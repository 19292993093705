import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { INote } from "../../features/ClaimNotes/types"

type IState = {
  // 1.
  filterDrawerOpen: boolean
  // 2.
  verifiedPhones: string[]
  // 3.
  currentPage: number
  fromDate: string
  toDate: string
  category1Values: string[]
  category2Values: string[]
  category3Values: string[]
  // 4.
  selectedClaimId: string
  selectedNote?: INote
}

export const initialState: IState = {
  /** 1. This state is used in Product Incentives page to
   * show/hide the filter drawer on smaller screens
   */
  filterDrawerOpen: false,
  /** 2. State for storing verified phone numbers for some time
   * in order to avoid sending OTP again for same number
   */
  verifiedPhones: [],
  /** 3. State for storing filters in Current Incentives page
   */
  currentPage: 1,
  fromDate: dayjs().startOf("month").toString(),
  toDate: dayjs().endOf("month").toString(),
  category1Values: [],
  category2Values: [],
  category3Values: [],
  /**
   * 4. State for storing the claim ID for showing notes modal
   * and state for storing selected note to be edited in edit note modal
   */
  selectedClaimId: "",
  selectedNote: undefined,
}

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    resetUiState: () => {
      return initialState
    },
    // 1.
    closeFilterDrawer: (state) => {
      state.filterDrawerOpen = false
    },
    openFilterDrawer: (state) => {
      state.filterDrawerOpen = true
    },
    // 2.
    setVerifiedPhones: (state, { payload }: PayloadAction<string[]>) => {
      state.verifiedPhones = payload
    },
    // 3.
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload
    },
    setFromDate: (state, { payload }: PayloadAction<string>) => {
      state.fromDate = payload
    },
    setToDate: (state, { payload }: PayloadAction<string>) => {
      state.toDate = payload
    },
    setCategory1Values: (state, { payload }: PayloadAction<string[]>) => {
      state.category1Values = payload
    },
    setCategory2Values: (state, { payload }: PayloadAction<string[]>) => {
      state.category2Values = payload
    },
    setCategory3Values: (state, { payload }: PayloadAction<string[]>) => {
      state.category3Values = payload
    },
    // 4.
    setSelectedClaimId: (state, { payload }: PayloadAction<string>) => {
      state.selectedClaimId = payload
    },
    setSelectedNote: (state, { payload }: PayloadAction<INote | undefined>) => {
      state.selectedNote = payload
    },
  },
})

export const {
  resetUiState,
  closeFilterDrawer,
  openFilterDrawer,
  setVerifiedPhones,
  setCurrentPage,
  setFromDate,
  setToDate,
  setCategory1Values,
  setCategory2Values,
  setCategory3Values,
  setSelectedClaimId,
  setSelectedNote,
} = uiSlice.actions

export default uiSlice.reducer
