import { ConfigProvider, Divider, Menu } from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import Button from "../../components/styled/Button.styled"
import ContainerFluid from "../../components/styled/ContainerFluid.styled"
import { Heading } from "../../components/styled/Heading"
import globals from "../../constants/globals"
import { MenuProps } from "antd"
import { ConsumerPaths } from "../../constants/paths"
import { useMemo, useState } from "react"
import { ConsumerPermissions } from "../../constants/permissions"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import cardImage from "../../assets/images/PrepaidCard.png"

const { BPS_MYACCOUNT_HOME } = globals
const { MY_ACCOUNT_PATH, MY_PROFILE_PATH, MY_INBOX_PATH } = ConsumerPaths
const { VIEW_MY_CLAIMS, VIEW_MY_PROFILE, VIEW_INBOX } = ConsumerPermissions

const items = [
  {
    key: "1",
    label: "Claims",
    path: MY_ACCOUNT_PATH,
    permissionCode: VIEW_MY_CLAIMS,
  },
  {
    key: "2",
    label: "My Information",
    path: MY_PROFILE_PATH,
    permissionCode: VIEW_MY_PROFILE,
  },
  {
    key: "3",
    label: "Inbox",
    path: MY_INBOX_PATH,
    permissionCode: VIEW_INBOX,
  },
]

const MyAccount = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([
    // Setting the selected key using URL
    items.find(
      (item) => item.path === pathname || item.path === pathname.slice(0, -1)
    )?.key as string,
  ])

  const { permissions } = useGetMyPermissions()

  const onSelect: MenuProps["onSelect"] = ({ key }) => {
    navigate(items.find((item) => item.key === key)?.path || "")
    setSelectedKeys([key])
  }

  const itemsToShow = useMemo(
    () =>
      items
        .filter((item) => permissions.includes(item.permissionCode))
        .map((item) => ({ key: item.key, label: item.label })),
    [permissions]
  )

  return (
    <ContainerFluid className="bg-light px-4 px-md-5">
      <Heading heading="My Account" />

      <div className="row mb-2 justify-content-center bg-white">
        <div className="col-lg-3 col-md-4 pt-4 border">
          <div>
            <div className="d-flex justify-content-center">
              <img alt="card" src={cardImage} width="150px" className="py-3" />
            </div>
            <a
              aria-current="page"
              rel="noreferrer"
              href={BPS_MYACCOUNT_HOME}
              target="_blank"
            >
              <Button type="button">Activate Card / Check Balance</Button>
            </a>
          </div>

          <Divider />

          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  colorItemBgSelected: "rgba(0, 0, 0, 0.06)",
                  radiusItem: 0,
                },
              },
            }}
          >
            <Menu
              selectedKeys={selectedKeys}
              onSelect={onSelect}
              items={itemsToShow}
            />
          </ConfigProvider>
        </div>
        <div className="col-lg-9 col-md-8 pt-4 border">{children}</div>
      </div>
    </ContainerFluid>
  )
}

export default MyAccount
