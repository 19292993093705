import { Link } from "./CustomLink"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { VIEW_DEALER_UPLOADER, VIEW_PRODUCT_UPLOADER } = AdminPermissions
const { DEALER_UPLOADER_PATH, PRODUCT_UPLOADER_PATH } = AdminPaths

const items = [
  {
    label: <Link to={DEALER_UPLOADER_PATH}>Dealer Uploader</Link>,
    permission: VIEW_DEALER_UPLOADER,
    key: "1",
  },
  {
    label: <Link to={PRODUCT_UPLOADER_PATH}>Product Uploader</Link>,
    permission: VIEW_PRODUCT_UPLOADER,
    key: "2",
  },
]

type UploaderNavItemsProps = {
  toggleCollapse: () => void
}

const UploaderNavItems = ({ toggleCollapse }: UploaderNavItemsProps) => {
  return (
    <DropdownNavbar
      items={items}
      label="Uploaders"
      toggleCollapse={toggleCollapse}
    />
  )
}

export default UploaderNavItems
