import { Popover } from "antd"
import styled from "styled-components"

const Container = styled.div`
  min-width: 250px;
  max-width: 500px;
`

const TextContainer = styled.div`
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center;
  word-wrap: break-word;
`

const HeadingContainer = styled(TextContainer)`
  background-color: #e6e6e6;
  font-weight: 700;
`

type Props = {
  title?: string
  description?: string | string[]
  children: React.ReactNode
}

const CustomTooltip = ({ title, description, children }: Props) => {
  return (
    <>
      <Popover
        content={
          <Container>
            {title && <HeadingContainer>{title}</HeadingContainer>}
            {description ? (
              typeof description === "string" ? (
                <TextContainer>{description}</TextContainer>
              ) : (
                description.map((desc, index) => (
                  <TextContainer key={index}>{desc}</TextContainer>
                ))
              )
            ) : null}
          </Container>
        }
      >
        {children}
      </Popover>
    </>
  )
}

export default CustomTooltip
