import { useLocation } from "react-router-dom"

import { AdminPaths } from "../../constants/paths"
import { IClaim } from "../../types/graphqlResponse"
import notesSvg from "../../assets/images/notes.svg"
import notesGreenSvg from "../../assets/images/notes-green.svg"
import { useAppDispatch } from "../../redux/hooks"
import { setSelectedClaimId } from "../../redux/ui/uiSlice"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { AdminPermissions } from "../../constants/permissions"

const { CLAIMS_PATH } = AdminPaths

const { VIEW_CLAIM_NOTES } = AdminPermissions

type Props = { record: IClaim }

const IconAndFirstname = ({ record }: Props) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const { permissions } = useGetMyPermissions()

  const isClaimsPage =
    pathname === CLAIMS_PATH || pathname.slice(0, -1) === CLAIMS_PATH

  const onClick = () => {
    dispatch(setSelectedClaimId(record.id))
  }

  const hasNotes = record.claimHasNotes

  const hasPermissionForNotes = permissions.includes(VIEW_CLAIM_NOTES)

  return isClaimsPage && hasPermissionForNotes ? (
    <>
      {record.firstName}{" "}
      <img
        src={hasNotes ? notesGreenSvg : notesSvg}
        alt="notes"
        className="cursor-pointer"
        onClick={onClick}
      />
    </>
  ) : (
    <>{record.firstName}</>
  )
}

export default IconAndFirstname
