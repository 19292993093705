import { CommonPaths } from "../constants/paths"
import { CommonPermissions } from "../constants/permissions"
import { IRoute } from "../types/route"
import { lazy } from "react"

const ResourcesPR = lazy(() => import("../pages/ResourcesPR"))

const { VIEW_RESOURCES } = CommonPermissions

const { RESOURCES_RULES_PATH } = CommonPaths

const commonRoutes: IRoute[] = [
  {
    element: <ResourcesPR />,
    path: RESOURCES_RULES_PATH,
    permissionCode: VIEW_RESOURCES,
  },
]

export default commonRoutes
