import HeroSection from "../components/styled/HeroSection.styled"
import globals from "../constants/globals"
import ProgramHeader from "../components/Home/ProgramHeader"
import Announcements from "../components/Home/Announcements"
import { Heading } from "../components/styled/Heading"
import Container from "../components/styled/Container.styled"
import AnnouncementButton from "../components/Home/AnnouncementButton"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import { AdminPermissions } from "../constants/permissions"
import { ArrowDownOutlined } from "@ant-design/icons"

const { PROGRAM_NAME, IS_ADMIN } = globals
const { ADD_HOME_ANNOUNCEMENT } = AdminPermissions

const Home = () => {
  const { permissions } = useGetMyPermissions()

  const hasPermissionToAddAnnouncement =
    permissions.includes(ADD_HOME_ANNOUNCEMENT) && IS_ADMIN

  return (
    <div className="vh-250">
      {IS_ADMIN && (
        <>
          <div className="container pt-5 px-4 mb-4">
            <Heading
              heading="Program Announcements"
              description="Preview the participant site home page, add program announcements, and customize the home page copy."
            />
          </div>
          <hr className="participant-preview-border" />
          <div className="participant-preview-text">
            <ArrowDownOutlined className="pe-4" />
            Participant Site Preivew
            <ArrowDownOutlined className="ps-4" />
          </div>
        </>
      )}

      <HeroSection
        mutedText="Welcome To"
        heading={PROGRAM_NAME}
        description={<ProgramHeader />}
      />

      <Container>
        <Heading
          heading="Announcements"
          render={hasPermissionToAddAnnouncement && <AnnouncementButton />}
        />

        <Announcements />
      </Container>
    </div>
  )
}

export default Home
