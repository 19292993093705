export const isMobile: boolean =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

export const isTouchableScreen: boolean = navigator.maxTouchPoints > 0

export const getStringFromHTML = (string: string) => {
  return string?.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, "");
};
