import { Checkbox, Form } from "antd"

const BikeInfoOptIn = () => {
  return (
    <Form.Item name="bikeInfoOptIn" valuePropName="checked">
      <Checkbox>
        I would like to receive information from BPS on more bike related items
      </Checkbox>
    </Form.Item>
  )
}

export default BikeInfoOptIn
