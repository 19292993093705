import Layout from "../components/Layout/Layout"
import SignIn from "../pages/SignIn"
import SignOut from "../pages/SignOut"
import commonRoutes from "./CommonRoutes"
import AuthorisedRoute from "../components/AuthorisedRoute"
import { useRoutes } from "react-router-dom"
import { lazy, useMemo } from "react"
import { AdminPermissions, CommonPermissions } from "../constants/permissions"
import type { IRoute, IParentRoute } from "../types/route"
import { AdminPaths, CommonPaths } from "../constants/paths"
import Home from "../pages/Home"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import UpdatePassword from "../pages/UpdatePassword"
import NotFound from "../pages/NotFound"
import ForgotPassword from "../pages/ForgotPassword"
import Users from "../pages/admin/Users"
import InvoiceOrder from "../pages/admin/InvoiceOrder"
import ChangeAddressReport from "../pages/admin/ChangeAddressReport"
import ProductUploader from "../pages/admin/ProductUploader"

const EnrollmentList = lazy(() => import("../pages/admin/EnrollmentList"))
const DealerUpload = lazy(() => import("../pages/admin/DealerUpload"))
const Quizzes = lazy(() => import("../pages/admin/Quizzes"))
const ProgramCommunications = lazy(
  () => import("../pages/admin/ProgramCommunications")
)
const Claims = lazy(() => import("../pages/admin/Claims"))
const ClaimsApproval = lazy(() => import("../pages/admin/ClaimsApproval"))
const ClaimReport = lazy(() => import("../pages/admin/ClaimReport"))
const EnrollmentReport = lazy(() => import("../pages/admin/EnrollmentReport"))
const AddIncentive = lazy(() => import("../pages/admin/AddIncentive"))
const UpdateIncentive = lazy(() => import("../pages/admin/UpdateIncentive"))
const AddUser = lazy(() => import("../pages/admin/AddUser"))
const EditClaim = lazy(() => import("../pages/admin/EditClaim"))
const ContactUs = lazy(() => import("../pages/ContactUs"))
const Dashboard = lazy(() => import("../pages/admin/Dashboard"))
const CurrentIncentives = lazy(() => import("../pages/CurrentIncentives"))
const ResourcesFAQ = lazy(() => import("../pages/ResourcesFAQ"))

const {
  VIEW_ADMIN_HOME,
  VIEW_ENROLLMENT_LIST,
  VIEW_DEALER_UPLOADER,
  VIEW_QUIZZES,
  VIEW_PROGRAM_COMMUNICATIONS,
  VIEW_CLAIMS,
  VIEW_CLAIM_REPORT,
  VIEW_ADD_INCENTIVE,
  VIEW_ADD_USER,
  VIEW_ENROLLMENT_REPORT,
  VIEW_CLAIM_APPROVAL,
  VIEW_EDIT_CLAIM,
  VIEW_USERS_LIST,
  UPDATE_CURRENT_INCENTIVE,
  VIEW_INVOICE_ORDER,
  VIEW_CHANGE_ADDRESS_REPORT,
  VIEW_PRODUCT_UPLOADER,
  VIEW_ANNOUNCEMENT,
} = AdminPermissions

const {
  DEALER_UPLOADER_PATH,
  ENROLLMENT_LIST_PATH,
  QUIZZES_PATH,
  PROGRAM_COMMUNICATIONS_PATH,
  CLAIMS_PATH,
  CLAIM_REPORT_PATH,
  ADD_INCENTIVE_PATH,
  UPDATE_INCENTIVE_PATH,
  ADD_USER_PATH,
  ENROLLMENT_REPORT_PATH,
  CLAIM_APPROVAL_PATH,
  EDIT_CLAIM_PATH,
  USERS_LIST_PATH,
  INVOICE_ORDER_PATH,
  CHANGE_ADDRESS_REPORT_PATH,
  PRODUCT_UPLOADER_PATH,
  ANNOUNCEMENT_PATH,
} = AdminPaths

const {
  SIGNIN_PATH,
  SIGNOUT_PATH,
  UPDATE_PASSWORD_PATH,
  HOME_PATH,
  FORGOT_PASSWORD_PATH,
  CONTACTUS_PATH,
  RESOURCES_FAQ_PATH,
  CURRENT_INCENTIVES_PATH,
} = CommonPaths

const { VIEW_CONTACTUS, VIEW_CURRENT_INCENTIVES, VIEW_RESOURCES } =
  CommonPermissions

const AdminRoutes = () => {
  const { permissions } = useGetMyPermissions()

  const adminPrivateRoutes: IRoute[] = [
    {
      element: <Dashboard />,
      path: HOME_PATH,
      permissionCode: VIEW_ADMIN_HOME,
    },
    {
      element: <Home />,
      path: ANNOUNCEMENT_PATH,
      permissionCode: VIEW_ANNOUNCEMENT,
    },
    {
      element: <EnrollmentList />,
      path: ENROLLMENT_LIST_PATH,
      permissionCode: VIEW_ENROLLMENT_LIST,
    },
    {
      element: <DealerUpload />,
      path: DEALER_UPLOADER_PATH,
      permissionCode: VIEW_DEALER_UPLOADER,
    },
    {
      element: <ProductUploader />,
      path: PRODUCT_UPLOADER_PATH,
      permissionCode: VIEW_PRODUCT_UPLOADER,
    },
    {
      element: <Quizzes />,
      path: QUIZZES_PATH,
      permissionCode: VIEW_QUIZZES,
    },
    {
      element: <ProgramCommunications />,
      path: PROGRAM_COMMUNICATIONS_PATH,
      permissionCode: VIEW_PROGRAM_COMMUNICATIONS,
    },
    {
      element: <Claims />,
      path: CLAIMS_PATH,
      permissionCode: VIEW_CLAIMS,
    },
    {
      element: <ClaimsApproval />,
      path: CLAIM_APPROVAL_PATH,
      permissionCode: VIEW_CLAIM_APPROVAL,
    },
    {
      element: <ClaimReport />,
      path: CLAIM_REPORT_PATH,
      permissionCode: VIEW_CLAIM_REPORT,
    },
    {
      element: <EnrollmentReport />,
      path: ENROLLMENT_REPORT_PATH,
      permissionCode: VIEW_ENROLLMENT_REPORT,
    },
    {
      element: <ChangeAddressReport />,
      path: CHANGE_ADDRESS_REPORT_PATH,
      permissionCode: VIEW_CHANGE_ADDRESS_REPORT,
    },
    {
      element: <AddIncentive />,
      path: ADD_INCENTIVE_PATH,
      permissionCode: VIEW_ADD_INCENTIVE,
    },
    {
      element: <UpdateIncentive />,
      path: UPDATE_INCENTIVE_PATH,
      permissionCode: UPDATE_CURRENT_INCENTIVE,
    },
    {
      element: <AddUser />,
      path: ADD_USER_PATH,
      permissionCode: VIEW_ADD_USER,
    },
    {
      element: <Users />,
      path: USERS_LIST_PATH,
      permissionCode: VIEW_USERS_LIST,
    },
    {
      element: <EditClaim />,
      path: EDIT_CLAIM_PATH,
      permissionCode: VIEW_EDIT_CLAIM,
    },
    {
      element: <InvoiceOrder />,
      path: INVOICE_ORDER_PATH,
      permissionCode: VIEW_INVOICE_ORDER,
    },
    {
      element: <ContactUs />,
      path: CONTACTUS_PATH,
      permissionCode: VIEW_CONTACTUS,
    },
    {
      element: <CurrentIncentives />,
      path: CURRENT_INCENTIVES_PATH,
      permissionCode: VIEW_CURRENT_INCENTIVES,
    },
    {
      element: <ResourcesFAQ />,
      path: RESOURCES_FAQ_PATH,
      permissionCode: VIEW_RESOURCES,
    },
    ...commonRoutes,
  ]

  const filteredAdminPrivateRoutes = adminPrivateRoutes.map((route: IRoute) =>
    permissions.includes(route.permissionCode)
      ? route
      : {
          ...route,
          element: <NotFound />,
        }
  )

  const adminRoutes: IParentRoute = useMemo(
    () => [
      {
        element: <Layout />,
        children: [
          {
            element: <AuthorisedRoute />,
            children: filteredAdminPrivateRoutes,
          },
        ],
      },
      { element: <SignOut />, path: SIGNOUT_PATH },
      { element: <ForgotPassword />, path: FORGOT_PASSWORD_PATH },
      { element: <UpdatePassword />, path: UPDATE_PASSWORD_PATH },
      { element: <SignIn />, path: SIGNIN_PATH },
      { element: <NotFound />, path: "*" },
    ],
    [filteredAdminPrivateRoutes]
  )

  return useRoutes(adminRoutes)
}

export default AdminRoutes
