import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AdminPermissions } from "../../constants/permissions"
import type { IPermission } from "../../types/permission"
import { getAuthToken } from "../../utils/auth"
import { getMillisecondsFromTokenTime } from "../../utils/date"

const token = getAuthToken()
const TOKEN_TIMEOUT_DEDUCTION = 60 * 1000

interface InitialState {
  permissions: IPermission[]
  loggedIn: boolean
  /** Value used to start a timer and fetch a new token when timer finishes */
  tokenTimeout: number
}

const initialState: InitialState = {
  permissions: [],
  loggedIn: !!token,
  tokenTimeout: 0,
}

const { VIEW_ANNOUNCEMENT } = AdminPermissions

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setPermissions: (state, { payload }: PayloadAction<IPermission[]>) => {
      // TODO: added for US team. Need to remove
      state.permissions = [...payload, VIEW_ANNOUNCEMENT]
    },
    login: (state) => {
      state.loggedIn = true
    },
    /** Resetting auth slice state on log out */
    logout: (state) => {
      state.loggedIn = false
      state.tokenTimeout = 0
      state.permissions = []
    },
    /**
     * This function is called on log in and when a new token is received using refresh token
     * Sets the value to be  before the actual timeout
     * This is done to make sure we already have a new token before current one expires
     */
    setTokenTimeout: (state, { payload }: PayloadAction<string>) => {
      state.tokenTimeout =
        getMillisecondsFromTokenTime(payload) - TOKEN_TIMEOUT_DEDUCTION
    },
  },
})

export const { setPermissions, login, logout, setTokenTimeout } =
  authSlice.actions

export default authSlice.reducer
