import { ReactNode, useState } from "react"
import styled from "styled-components"
import heroImage from "../../assets/images/HeaderImage.jpg"
import globals from "../../constants/globals"

type HeroSectionContainerProps = {
  vh: number
}

const HeroSectionContainer = styled.div<HeroSectionContainerProps>`
  position: relative;
  img {
    width: 100%;
    @media screen and (min-width: 768px) {
      ${({ vh }) => vh >= 50 && `min-height: 450px;`}
      height: ${({ vh }) => `${vh}vh`};
      object-fit: cover;
    }
  }

  & > div {
    text-align: center;
    background-color: hsla(0, 100%, 0%, 0.6);
    padding: 3rem;
    color: #fff;
    min-width: 100%;

    @media screen and (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 45%;
    }

    @media screen and (min-width: 992px) {
      max-width: 55%;
    }
  }
`

const Heading = styled.h1`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3rem;
`
const Banner = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryColor};
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  .anticon {
    color: #fff;
  }

  & > div {
    padding: 3rem;
    color: #fff;
    width: 80%;
    margin: auto;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
`

const { IS_ADMIN, ADMIN_PROGRAM_HEADER } = globals

type IHeroSectionProps = {
  mutedText?: string
  heading?: string
  description?: ReactNode
  vh?: number
}

const HeroSection = ({ heading, description, vh }: IHeroSectionProps) => {
  const [showText, setShowText] = useState<boolean>(false)
  const toggleText = () => setShowText((val) => !val)
  return (
    <>
      <HeroSectionContainer vh={vh || 50}>
        <img src={heroImage} alt="Cover" onLoad={toggleText} />
        {showText && (
          <div>
            <Heading>{heading}</Heading>
            {IS_ADMIN && <h5>Administrator Module</h5>}
            {IS_ADMIN && <h4>{ADMIN_PROGRAM_HEADER}</h4>}
          </div>
        )}
      </HeroSectionContainer>
      <Banner>
        <div>
          <h2>Welcome to the Kenda Advanced Sales Rewards Program</h2>
          <div className="fs-5">{description}</div>
        </div>
      </Banner>
    </>
  )
}

export default HeroSection
