import CustomTooltip from "../CustomTooltip"

type BonusCalculationsProps = {
  bonusValue: string
  bonusCriteria: string | null
}

const BonusCalculations = ({
  bonusValue,
  bonusCriteria,
}: BonusCalculationsProps) => {
  /** If `bonusCriteria` starts with a new line, remove it
   * If `bonusCriteria` has new lines, split it into an array
   * If `bonusCriteria` does not exist, give empty array
   */
  const bonusCriteriaArray = bonusCriteria
    ? bonusCriteria.startsWith("\n")
      ? bonusCriteria.slice(1).split("\n")
      : bonusCriteria.split("\n")
    : []

  return (
    <CustomTooltip
      title={bonusCriteriaArray.length > 0 ? "Calculations" : "No Calculations"}
      description={bonusCriteriaArray}
    >
      {bonusValue}
    </CustomTooltip>
  )
}

export default BonusCalculations
