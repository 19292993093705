import { Form, Input } from "antd"

const PasswordToConfirmChanges = () => {
  return (
    <Form.Item
      name="pwd"
      label="Enter Password to Confirm Changes"
      rules={[
        {
          required: true,
          message: "Please enter a password",
        },
      ]}
    >
      <Input.Password disabled={false} autoComplete="new-password" />
    </Form.Item>
  )
}

export default PasswordToConfirmChanges
