import styled from "styled-components"

const Button = styled.button.attrs(() => ({
  className: "btn",
}))`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryColor} !important;
  color: #fff !important;
  border-color: ${({ theme }) => theme.colors.primaryColor};
  border-radius: 0;
  font-weight: 600;
  &:hover {
    background-color: #ccc !important;
  }

  &:focus {
    background-color: #ccc;
    border-color: #ccc;
  }

  &:disabled {
    background-color: #ccc;
    color: #888;
    border: none;
  }
`

const SecondaryButton = styled.button.attrs(() => ({
  className: "secondary-btn",
}))`
  width: 100%;
  background-color: #fff !important;
  color: #888 !important;
  border: 1px solid #888 !important;
  padding: 0.4rem 1.2rem;
  border-radius: 0;
  font-weight: 600;
  &:hover {
    background-color: #ccc;
  }

  &:focus,
  &:disabled {
    background-color: #ccc;
    border: #ccc;
    color: #888;
  }
  &:focus {
    outline: none;
  }
`

export default Button

export { SecondaryButton }
