import { gql } from "@apollo/client"

// Admin Side
const DEALER_UPLOAD_SUBSCRIPTION = gql`
  subscription DealerFileUpdateStatus {
    dealerFileUpdateStatus {
      message
      type
    }
  }
`
const PRODUCT_UPLOAD_SUBSCRIPTION = gql`
  subscription ProductFileUpdateStatus {
    productFileUpdateStatus {
      message
      type
    }
  }
`

const GENERATE_ORDER_UPDATE_SUBSCRIPTION = gql`
  subscription GenerateOrderUpdateStatus {
    generateOrderUpdateStatus {
      type
      message
    }
  }
`

export {
  DEALER_UPLOAD_SUBSCRIPTION,
  PRODUCT_UPLOAD_SUBSCRIPTION,
  GENERATE_ORDER_UPDATE_SUBSCRIPTION,
}
