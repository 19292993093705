import { ReactNode } from "react"
import styled from "styled-components"
import headerImage from "../../assets/images/HeaderImage.jpg"

const HeadingContainer = styled.div<{ showImage: boolean; render: boolean }>`
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
  div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      border-left: 6px solid ${({ theme }) => theme.colors.primaryColor};
      padding-left: 1.5rem;
      font-size: 2rem;
      font-weight: 700;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
    }
    p {
      padding-top: 1rem;
      padding-left: calc(1.5rem + 6px);
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  ${({ showImage, render }) =>
    showImage
      ? `
      div:last-child {
        flex: 1;
        img {
          width: 100%;
        }
      }`
      : render
        ? `
      div:last-child {
        flex: 1;
        text-align: right;
        button {
          width: auto;
          min-width: 300px;
        }
      }
      `
        : ""}

  @media screen and (max-width: 768px) {
    ${({ showImage, render }) =>
    showImage
      ? `flex-direction: column-reverse;`
      : render
        ? `
          flex-direction: column;
          text-align: center;
          span {
            border-left: none !important;
          }
          div:last-child {
            text-align: center;
            button {
              width: 90%;
            }
            a {
              width: 100%;
            }
          }
      `
        : ""};
  }
`

type IHeadProps = {
  module?: string
  heading: string
  description?: string
  showImage?: boolean
  render?: ReactNode
}

const Heading = ({
  module,
  heading,
  description,
  showImage = false,
  render,
}: IHeadProps) => (
  <HeadingContainer showImage={showImage} render={render ? true : false}>
    <div>
      <span>{heading}</span>
      {module && <h5 className="text-secondary ms-4 ps-2">{module}</h5>}
      {description && <p>{description}</p>}
    </div>
    {showImage ? (
      <div>
        <img src={headerImage} alt="cover" />
      </div>
    ) : (
      render && <div>{render}</div>
    )}
  </HeadingContainer>
)

export { Heading }
