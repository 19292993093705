import { BrowserRouter } from "react-router-dom"
import AdminRoutes from "./routes/AdminRoutes"
import ConsumerRoutes from "./routes/ConsumerRoutes"
import globals from "./constants/globals"
import useGetMyPermissions from "./hooks/useGetMyPermissions"
import GlobalStyles from "./components/styled/GlobalStyles"
import FullScreenSpinner from "./components/styled/FullScreenSpinner.styled"
import ScrollToTop from "./components/Layout/ScrollToTop"
import { useAuthStatus } from "./hooks/useAuthStatus"
import AutoLogout from "./utils/AutoSignout"
import GetRefreshToken from "./utils/GetRefreshToken"
import ReactGA from "react-ga4"

const { IS_ADMIN, TRACKING_ID } = globals

if (!IS_ADMIN) {
  ReactGA.initialize(TRACKING_ID)
}

const App = () => {
  const { loading } = useGetMyPermissions()
  const { loggedIn } = useAuthStatus()

  if (loading) {
    return <FullScreenSpinner />
  }

  return (
    <BrowserRouter>
      {/* Scrolls To Top on Location Change */}
      <ScrollToTop />
      <GlobalStyles />
      {loggedIn && <GetRefreshToken />}
      {loggedIn && <AutoLogout />}
      {IS_ADMIN ? <AdminRoutes /> : <ConsumerRoutes />}
    </BrowserRouter>
  )
}

export default App
