import { useLazyQuery, useMutation } from "@apollo/client"
import { Alert, Button, Form, Input, Modal } from "antd"
import { useCallback, useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import globals from "../../constants/globals"
import { GET_OTP } from "../../graphql/mutations"
import { VERIFY_PHONE_OTP } from "../../graphql/queries"

type Props = {
  disabled?: boolean,
  phoneVerification?: boolean,
  checkPhoneVeification?: boolean,
  setTextCodeDisabled?: (e: boolean) => void,
  setVerified?: (e: boolean) => void
}



const PhoneNumber = ({ disabled, phoneVerification, checkPhoneVeification, setTextCodeDisabled, setVerified }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [open, setOpen] = useState<boolean>(false)
  const [otp, setOtp] = useState<string>("")
  const [alertMessage, setAlertMessage] = useState<JSX.Element>()
  const [isNumberVerify, setIsNumberVerify] = useState<boolean>(checkPhoneVeification!)
  const [verifOtpButton, setVerifyOtpButton] = useState<boolean>(false)
  const [phoneNumberEntered, setPhoneNumberEntered] = useState<string[]>([])
  const form = Form.useFormInstance()

  const clearAlert = () => {
    const timer = setTimeout(() => {
      setAlertMessage(() => { return <></> })
    }, 3000)
    return timer
  }

  useEffect(() => {
    setIsNumberVerify(checkPhoneVeification!)
  }, [checkPhoneVeification])


  const [getOtp] = useMutation(GET_OTP, {
    onCompleted() {
      setAlertMessage(() => {
        return <Alert type="success" message={`OTP sent successfully`} />
      })
      clearAlert()
    },
    onError(err) {
      setAlertMessage(() => {
        return <Alert type="error" message={err.message} />
      })
      clearAlert()
    },
  })
  const [verifyOtp] = useLazyQuery(VERIFY_PHONE_OTP, {
    onCompleted() {
      setAlertMessage(() => {
        return <Alert type="success" message={`Successfully setup for text messaging`} />
      })
      setVerified!(true)
      setPhoneNumberEntered((phNumberArr) => [...phNumberArr, form.getFieldValue('phone')])
      clearAlert()
      setIsNumberVerify(false)
      setTextCodeDisabled!(true)
      setTimeout(() => {
        setOpen(false)
        setVerifyOtpButton(false)
      }, 2000)
    },
    onError(err) {
      setAlertMessage(() => {
        return <Alert type="error" message={err.message} />
      })
      clearAlert()
      setIsNumberVerify(true)
    },
  })

  const getOtpHandler = useCallback(
    () => {
      setOpen(true)
      if (phoneNumberEntered.length > 0 ? !phoneNumberEntered.includes(phoneNumber) : true) {
        getOtp({
          variables: {
            phone: phoneNumber,
          },
        })
      } else {
        setAlertMessage(() => {
          return <Alert type="warning" message={`Number has been already verified`} />
        })
        clearAlert()
      }

    }, [getOtp, phoneNumber, phoneNumberEntered]
  )

  const otpVerfication = () => {
    verifyOtp({
      variables: {
        phone: phoneNumber,
        code: +otp,
      },
    })
  }

  useEffect(() => {
    if (phoneVerification) {
      getOtpHandler()
      setOtp("")
    }
  }, [phoneVerification, getOtpHandler])


  return (
    <>
      <Form.Item
        validateTrigger="onChange"
        rules={[
          {
            required: true,
            message: "Please enter your phone number",
          },
          {
            pattern: /^[1-9][0-9]{9}$/,
            message: "Phone number must be 10 digits",
          },
          ({ getFieldValue }) => ({
            validator: async (_, value) => {
              if (!!isNumberVerify && (phoneNumberEntered!.length > 0 ? !phoneNumberEntered?.includes(value) : true)) {
                return Promise.reject(new Error("Please verify phone number"))
              } else {
                return Promise.resolve()
              }
            }
          }),
          {
            validator(_, value) {
              if (value?.length === 10) {
                setTextCodeDisabled!(false)
                return Promise.resolve()
              } else {
                setTextCodeDisabled!(true)
                return Promise.reject()
              }
            }
          }
        ]}
        name="phone"
        label="Phone"
      >

        <Input
          autoComplete="new-password"
          disabled={disabled}
          onChange={(e) => {
            if (e.target.value.length === 10) {
              if (phoneNumberEntered!.length > 0 ? !phoneNumberEntered?.includes(e.target.value) : false) {
                setTimeout(() => {
                  form.setFields([{
                    name: 'phone',
                    errors: ["Please verify phone number"]
                  }])
                }, 500)
                setIsNumberVerify(true)
              }
              setPhoneNumber(e.target.value)
              setVerified!(false)
            }
          }}
        />


      </Form.Item>

      <Modal
        centered
        title="Enter OTP to verify phone number"
        open={open}
        onCancel={() => { setOpen(false); setTextCodeDisabled!(false); setVerifyOtpButton(false) }}
        cancelText="close"
        footer={
          <div className={"w-25 ms-auto"}>
            <Button onClick={() => { setOpen(false); setTextCodeDisabled!(false); setVerifyOtpButton(false) }}>Close</Button>
          </div>
        }
      >
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={globals.OTP_DIGIT}
          separator={<span>-</span>}
          containerStyle={"w-50 my-3 mx-auto"}
          inputStyle={"w-100"}
        />

        <Button
          className="mx-auto d-flex"
          onClick={() => { otpVerfication(); setVerifyOtpButton(true) }}
          disabled={verifOtpButton}
          style={{
            background:
              otp?.length === globals.OTP_DIGIT
                ? globals.PRIMARY_COLOR
                : " #ccc",
            color: "#fff",
          }}
        >
          Verify OTP
        </Button>
        <div className="my-3" style={{ height: "30px" }}>{alertMessage}</div>
      </Modal>
    </>
  )
}

export default PhoneNumber
