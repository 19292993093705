import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./redux/store"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./index.scss"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { ApolloProvider } from "@apollo/client"
import { ThemeProvider } from "styled-components"
import { theme } from "./components/styled/ThemeProvider"
import client from "./utils/apolloClient"
import { ConfigProvider } from "antd"
import globals from "./constants/globals"
import * as FullStory from "@fullstory/browser"
import Container from "./components/styled/Container.styled"
import Spinner from "./components/Spinner"

const container = document.getElementById("root")!
const root = createRoot(container)

const orgId = globals.FULLSTORY_ORGID!
FullStory.init({ orgId })

root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <Container>
          <Spinner />
        </Container>
      }
    >
      <Provider store={store}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <ConfigProvider
              theme={{
                token: {
                  fontFamily: "Roboto, sans-serif",
                },
              }}
            >
              <App />
            </ConfigProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
