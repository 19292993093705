import { Alert, Divider, Form } from "antd"
import axios from "axios"
import HTMLReactParser from "html-react-parser"
import queryString from "query-string"
import { Fragment, useEffect, useState } from "react"
import LoadingOverlay from "react-loading-overlay-ts"

import ProgramLogo from "../../assets/images/ProgramLogo.png"
import Footer from "../../components/Layout/Footer"
import Button from "../../components/styled/Button.styled"
import globals from "../../constants/globals"
import HeroSection from "../../components/styled/HeroSection.styled"
import { Address1, Address2, City, Email, FirstName, LastName, Phone, State, Zip } from "../../components/PhysicalCardContent/formItems"

const SHOW_DATA = (token: IToken) => `
query {
    physicalCardAddress(token: "${token}") {
        fname
        lname
        email
        phone
        address1
        address2    
        city
        state
        zip
        status
    }
}
`

const REDEEM_PHYSICAL = (
  token: IToken,
  fname: string,
  lname: string,
  email: string,
  phone: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string
) => `
    mutation {
        physicalCardRedeem(input: {token:"${token}", fname:"${fname}", lname: "${lname}", email: "${email}", phone: "${phone}", address1: "${address1}", address2: "${address2}", city: "${city}", state: "${state}", zip: "${zip}"}) {
            message
            error
        }
    }
`
const axiosGraphQL = axios.create({
  baseURL: process.env.REACT_APP_SYSTEM_URL,
})

type IToken = string | null | (string | null)[]

type IGetResponseFromBpsSystem = {
  fname: string
  lname: string
  email: string
  phone: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
}

const PhysicalCardContent = () => {
  const values = queryString.parse(window.location.search)
  const [showSpinner, setShowSpinner] = useState<boolean>(true)
  const [showSpinner2, setShowSpinner2] = useState<boolean>(false)
  const [spinnerText, setSpinnerText] = useState({
    text: "Loading your data...",
  })
  const [showSuccessMessage, setshowSuccessMessage] = useState<boolean>(false)
  const [showErrorMessage, setshowErrorMessage] = useState<boolean>(false)
  const [hideFields, setHideFields] = useState<boolean>(false)
  const [errorMessage, seterrorMessage] = useState<string>("")

  const [form] = Form.useForm()
  
  const { BRAND_LOGO_HEIGHT,PROGRAM_NAME } = globals
  useEffect(() => {
    try {
      axiosGraphQL
        .post("", {
          query: SHOW_DATA(values.token),
        })
        .then((result:any) => {
          setShowSpinner(false)
          if (result.data.data.physicalCardAddress.status !== "success") {
            if (
              result.data.data.physicalCardAddress.status ===
              "This token has already been redeemed and cannot be redeemed again"
            ) {
              setHideFields(true)
            }
            setshowErrorMessage(true)
            seterrorMessage(result.data.data.physicalCardAddress.status)
          } else {
            form.setFieldsValue(result.data.data.physicalCardAddress)
            setshowErrorMessage(false)
          }
        })
    } catch (err) {
      console.log(err)
      console.log("failed")
    }
  }, [])

  function handleSubmit(formValues: IGetResponseFromBpsSystem){
    setShowSpinner2(true)
    setSpinnerText({ text: "Your card request is being processed..." })

    axiosGraphQL
      .post("", {
        query: REDEEM_PHYSICAL(
          values.token,
          formValues.fname,
          formValues.lname,
          formValues.email,
          formValues.phone,
          formValues.address1,
          formValues.address2,
          formValues.city,
          formValues.state,
          formValues.zip
        ),
      })
      .then((result) => {
        console.log(result)
        setShowSpinner2(false)
        if (result.data.data.physicalCardRedeem.error) {
          setshowErrorMessage(true)
          seterrorMessage(result.data.data.physicalCardRedeem.message)
        } else {
          window.location.href = "/physicalcardredeemed"
          setshowErrorMessage(false)
          setshowSuccessMessage(true)
          seterrorMessage(result.data.data.physicalCardRedeem.message)
        }
      })
  }

  return (
    <Fragment>
      <LoadingOverlay
        active={showSpinner || showSpinner2}
        spinner
        text={spinnerText.text}
      >
        <div className="container-fluid p-4">
          <div className="row">
            <div className="col-md-10">
              <img
                src={ProgramLogo}
                className="d-inline-block align-top"
                height={BRAND_LOGO_HEIGHT}
                alt="Logo"
              />
            </div>
          </div>
        </div>
        <div className="container-fluid py-5">
          <div>
             <HeroSection
              mutedText={PROGRAM_NAME}
              heading="KENDA ASR"
            />
          </div>

          <div className="row py-5 justify-content-center">
            <div className="col-lg-8 col-11 border p-md-5 p-3">
              <Form
                form={form}
                className="row gx-3 justify-content-center"
                layout="vertical"
                onFinish={handleSubmit}
              >
                 <h5>1. Your Information</h5>
                <Alert
                  type="success"
                  style={{ display: showSuccessMessage ? "block" : "none" }}
                  message={
                    "You should receive your card in 10-15 business days."
                  }
                ></Alert>
                <Alert
                  type="error"
                  style={{ display: showErrorMessage ? "block" : "none" }}
                  message={HTMLReactParser(errorMessage)}
                ></Alert>
                   <div className="col-sm-6">
                  <FirstName />
                </div>
                <div className="col-sm-6">
                  <LastName />
                </div>
                <div className="col-sm-6">
                  <Email />
                </div>
                <div className="col-sm-6">
                  <Phone />
                </div>
                <Divider />
                <h5>
                 2. Mailing Address
                </h5>

                <div className="col-sm-6">
                  <Address1 />
                </div>
                <div className="col-sm-6">
                  <Address2 />
                </div>
                <div className="col-sm-6">
                  <City />
                </div>
                <div className="col-sm-3">
                  <State />
                </div>
                <div className="col-sm-3">
                  <Zip />
                </div>
                <div
                  className="row"
                  style={{ display: hideFields ? "none" : "block" }}
                >
                  <div className="col-sm-2 offset-sm-5">
                    <Button type="submit">Submit</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <Footer />
      </LoadingOverlay>
    </Fragment>
  )
}

export default PhysicalCardContent
