import { Form, Alert, Divider, Checkbox, UploadFile } from "antd"
import { useState } from "react"
import {
  CREATE_ENROLLMENT,
  SIGNIN,
  VALIDATE_ADDRESS,
  VALIDATE_ENROLLMENT_FORM,
} from "../../graphql/mutations"
import { useMutation } from "@apollo/client/react"
import Button, { SecondaryButton } from "../../components/styled/Button.styled"
import FileUpload from "../../components/FileUpload"
import globals from "../../constants/globals"
import EnrollmentSuccess from "../../components/EnrollmentForm/EnrollmentSuccess"
import HeroSection from "../../components/styled/HeroSection.styled"
import Password from "../../components/Form/Password"
import ConfirmPassword from "../../components/Form/ConfirmPassword"
import Spinner from "../../components/Spinner"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import LegalFirstName from "../../components/EnrollmentForm/LegalFirstName"
import LegalLastName from "../../components/EnrollmentForm/LegalLastName"
import GoesByName from "../../components/EnrollmentForm/GoesByName"
import JobTitle from "../../components/EnrollmentForm/JobTitle"
import WorkEmail from "../../components/EnrollmentForm/WorkEmail"
import PhoneNumber from "../../components/EnrollmentForm/PhoneNumber"
import Address2 from "../../components/EnrollmentForm/Address2"
import City from "../../components/EnrollmentForm/City"
import State from "../../components/EnrollmentForm/State"
import ZIP from "../../components/EnrollmentForm/ZIP"
import DealerZIP from "../../components/EnrollmentForm/DealerZIP"
import DealerState from "../../components/EnrollmentForm/DealerState"
import DealerCity from "../../components/EnrollmentForm/DealerCity"
import DealerNameExisting from "../../components/EnrollmentForm/DealerNameExisting"
import DealerNameNew from "../../components/EnrollmentForm/DealerNameNew"
import AddressAutocomplete from "../../components/EnrollmentForm/AddressAutocomplete"
import useGetProgramHeader from "../../hooks/useGetProgramHeader"
import trimFormValues from "../../utils/trimFormValues"
import BikeInfoOptIn from "../../components/EnrollmentForm/BikeInfoOptIn"
import { ISigninResponse } from "../../types/graphqlResponse"
import { setAuthToken, setIfConsumerLoggedInOnce } from "../../utils/auth"
import { useAppDispatch } from "../../redux/hooks"
import { login } from "../../redux/auth/authSlice"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import ReactGA from "react-ga4"

const { PROGRAM_NAME, IS_ADMIN } = globals

const EnrollmentForm = () => {
  const dispatch = useAppDispatch()
  const [checked, setChecked] = useState(false)
  const [isNewDealer, setIsNewDealer] = useState(false)
  const [readOnly, setReadOnly] = useState<boolean>(false)
  const [phoneNumberVerification, setPhoneNumberVerification] =
    useState<boolean>(false)
  const [checkPhoneVeification, setCheckPhoneVeification] =
    useState<boolean>(false)
  const [textCodeDisabled, setTextCodeDisabled] = useState<boolean>(true)
  const [blockSubmit, setBlockSubmit] = useState<boolean>(false)
  const [verified, setVerified] = useState<boolean>(false)
  const [isDealerAddressValidated, setIsDealerAddressValidated] = useState(true)
  const [isPersonalAddressValidated, setIsPersonalAddressValidated] =
    useState(true)

  const [form] = Form.useForm()

  const { loggedIn } = useAuthStatus()

  // Mutation for creating an enrollment
  const [
    enrollment,
    { loading: creatingEnrollment, error: errorEnrollment, reset },
  ] = useMutation(CREATE_ENROLLMENT)
  // Mutation for validating an enrollment
  const [
    validateEnrollment,
    { reset: validateReset, error: validateError, loading: validateLoading },
  ] = useMutation(VALIDATE_ENROLLMENT_FORM)
  // Mutation for validating addresses
  const [
    validateAddress,
    { reset: validateReset2, error: validateError2, loading: validateLoading2 },
  ] = useMutation<{ validateAddress: "true" | "false" }>(VALIDATE_ADDRESS)
  // Mutation for signing in user
  const [signinUser] = useMutation(SIGNIN)

  const { header } = useGetProgramHeader()

  // Form submit handler
  const onFinish = (values: any) => {
    setTextCodeDisabled(true)
    reset()
    validateReset()
    validateReset2()
    if (blockSubmit) {
      return
    }
    if (!readOnly) {
      return validateAndToggleReadOnly(values)
    }
    // Trim whitespace from values
    const trimmedValues = trimFormValues(values)

    const forApi = {
      enrolment: {
        ...trimmedValues,
        smsOptIn: checked,
        document: values.document?.fileList.map(
          (file: UploadFile) => file.originFileObj
        ),
        confirmPwd: undefined,
      },
    }
    enrollment({
      variables: forApi,
      onError: toggleReadOnly,
      // Sign in user after successful enrollment
      onCompleted: () => {
        signinUser({
          variables: {
            userName: forApi.enrolment.workEmail,
            password: forApi.enrolment.pwd,
            signingInFromConsumerOrAdmin: IS_ADMIN ? "admin" : "consumer",
          },
          onCompleted: (data: ISigninResponse) => {
            setAuthToken({
              token: data.signIn.token,
              refresh_token: data.signIn.refreshToken,
            })
            dispatch(login())
            ReactGA.event({
              category: "enrollment_success",
              action: "enrollment_success",
            })
            setIfConsumerLoggedInOnce()
          },
        })
      },
    })
  }

  const toggleReadOnly = (): void => setReadOnly((val) => !val)
  const toggleExistingNewDealer = () => {
    setIsNewDealer((val) => !val)
    form.resetFields([
      "dealerId",
      "dealerStreetAddress",
      "dealerState",
      "dealerCity",
      "dealerZip",
      "dealerShopName",
    ])
  }

  const validateAndToggleReadOnly = (values: any) => {
    const dealerAddressVariables = {
      dealerStreetAddress: form.getFieldValue("dealerStreetAddress"),
      dealerCity: form.getFieldValue("dealerCity"),
      dealerState: form.getFieldValue("dealerState"),
      dealerZip: form.getFieldValue("dealerZip"),
      secondary: form.getFieldValue("secondary"),
    }

    const personalAddressVariables = {
      dealerStreetAddress: form.getFieldValue("street1"),
      dealerCity: form.getFieldValue("city"),
      dealerState: form.getFieldValue("state"),
      dealerZip: form.getFieldValue("zip"),
      secondary: form.getFieldValue("street2"),
    }

    // Validate Personal Address
    validateAddress({
      variables: personalAddressVariables,
      onCompleted: (data) => {
        setIsPersonalAddressValidated(data.validateAddress === "true")
        // Validate Dealer Address if New Dealer
        if (isNewDealer && data.validateAddress === "true") {
          validateAddress({
            variables: dealerAddressVariables,
            onCompleted: (data) => {
              setIsDealerAddressValidated(data.validateAddress === "true")
              // Validate Enrollment, basically for duplicate email
              if (data.validateAddress === "true") {
                validateEnrollment({
                  variables: {
                    workEmail: values.workEmail,
                    dealerStreetAddress: values.dealerStreetAddress,
                    dealerZip: values.dealerZip,
                  },
                  onCompleted: () => {
                    window.scrollTo(0, 500)
                    toggleReadOnly()
                  },
                })
              }
            },
          })
        } else {
          // Validate Enrollment, same as nested one above
          if (data.validateAddress === "true") {
            validateEnrollment({
              variables: { workEmail: values.workEmail },
              onCompleted: () => {
                window.scrollTo(0, 500)
                toggleReadOnly()
              },
            })
          }
        }
      },
    })
  }

  if (loggedIn) {
    return (
      <>
        <Header />
        <div className="vh-150">
          <EnrollmentSuccess />
        </div>
        <Footer />
      </>
    )
  }

  const ConfirmationPageAlert = (
    <>
      <strong>CONFIRMATION PAGE: </strong>
      <span>
        Please confirm that your information is correct, then click on the Enroll Now
        button to complete your enrollment.
      </span>
    </>
  )

  return (
    <>
      <Header ifConsumerNotLoggedIn />
      <div className="vh-250">
        <HeroSection
          mutedText={PROGRAM_NAME}
          heading="Enroll Now"
          description={header}
        />

        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-11 border p-md-5 p-3">
              {readOnly && (
                <Alert
                  type="info"
                  className="mb-4"
                  message={ConfirmationPageAlert}
                />
              )}
              <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                scrollToFirstError
                disabled={readOnly}
                requiredMark={false}
              >
                <h5>1. Dealer Information</h5>

                <div className="row">
                  <Checkbox
                    onChange={toggleExistingNewDealer}
                    checked={isNewDealer}
                    className="my-3"
                  >
                    New Dealer
                  </Checkbox>

                  {isNewDealer ? (
                    <div className="col-12">
                      <DealerNameNew />
                    </div>
                  ) : (
                    <div className="col-12">
                      <DealerNameExisting form={form} />
                    </div>
                  )}

                  <div className="col-12">
                    <AddressAutocomplete
                      form={form}
                      name="dealerStreetAddress"
                      label="Dealer Address"
                      formItemNamesToUpdate={[
                        "none",
                        "dealerCity",
                        "dealerState",
                        "dealerZip",
                      ]}
                      requiredMessage="Please enter a dealer address"
                      disabled={!isNewDealer || readOnly}
                    />
                  </div>

                  <div className="col-sm-6">
                    <DealerCity disabled={!isNewDealer || readOnly} />
                  </div>

                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col">
                        <DealerState disabled={!isNewDealer || readOnly} />
                      </div>
                      <div className="col">
                        <DealerZIP disabled={!isNewDealer || readOnly} />
                      </div>
                    </div>
                  </div>
                </div>

                <Divider />

                <h5>2. Personal Contact Information</h5>

                <div className="row">
                  {[
                    <LegalFirstName />,
                    <LegalLastName />,
                    <GoesByName />,
                    <JobTitle />,
                    <WorkEmail />,
                    <PhoneNumber
                      phoneVerification={phoneNumberVerification}
                      checkPhoneVeification={checkPhoneVeification}
                      setTextCodeDisabled={(state) => {
                        setTextCodeDisabled(state)
                        setPhoneNumberVerification(false)
                        !!state
                          ? form.setFields([{ name: "phone", errors: [] }])
                          : form.setFields([{ name: "phone", errors: [] }])
                      }}
                      setVerified={(state) => {
                        setVerified(state)
                      }}
                    />,
                    // For Address 1
                    <AddressAutocomplete
                      form={form}
                      name="street1"
                      label="Address 1"
                      formItemNamesToUpdate={[
                        "street2",
                        "city",
                        "state",
                        "zip",
                      ]}
                      requiredMessage="Please enter an address"
                      disabled={readOnly}
                    />,
                    <Address2 />,
                    <City />,
                  ].map((item, index) => (
                    <div className="col-sm-6" key={index}>
                      {item}
                    </div>
                  ))}

                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col">
                        <State />
                      </div>
                      <div className="col">
                        <ZIP />
                      </div>
                    </div>
                  </div>
                </div>

                <FileUpload setBlockSubmit={setBlockSubmit} maxFileCount={10} />

                <Divider />

                <h5>3. Mobile Phone</h5>

                <Checkbox
                  onChange={(e) => {
                    setChecked(e.target.checked)
                    if (verified === false) {
                      setCheckPhoneVeification(e.target.checked)
                    }
                    e.target.checked === true &&
                      textCodeDisabled === false &&
                      verified === false
                      ? form.setFields([
                        {
                          name: "phone",
                          errors: ["Please verify phone number"],
                        },
                      ])
                      : form.setFields([{ name: "phone", errors: [] }])
                  }}
                  checked={checked}
                  disabled={textCodeDisabled}
                >
                  I want to receive SMS messages regarding this program
                </Checkbox>

                <div className="col-md-2 my-3">
                  <Button
                    type="button"
                    onClick={() => {
                      setPhoneNumberVerification(true)
                    }}
                    disabled={textCodeDisabled}
                  >
                    Text Code
                  </Button>
                </div>

                <Divider />

                <h5>4. Create Your Account </h5>

                <div className="row">
                  <div className="col-sm-6">
                    <Password form={form} />
                  </div>
                  <div className="col-sm-6">
                    <ConfirmPassword />
                  </div>
                </div>

                <Divider />

                <h5>5. Bike Information Opt In </h5>

                <BikeInfoOptIn />
              </Form>

              {/* Spinner */}
              <div className="row">
                {(creatingEnrollment ||
                  validateLoading ||
                  validateLoading2) && <Spinner />}
              </div>

              {/* Info Message For Address Validation */}
              {!isDealerAddressValidated && (
                <div className="col-12 mb-3">
                  <Alert
                    type="error"
                    message="Please enter the correct dealer address"
                  />
                </div>
              )}
              {!isPersonalAddressValidated && (
                <div className="col-12 mb-3">
                  <Alert
                    type="error"
                    message="Please enter correct personal address"
                  />
                </div>
              )}

              {/* Error Messages */}
              {errorEnrollment && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={errorEnrollment.message} />
                </div>
              )}
              {validateError && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={validateError.message} />
                </div>
              )}
              {validateError2 && (
                <div className="col-12 mb-3">
                  <Alert type="error" message={validateError2.message} />
                </div>
              )}
              <div className="row justify-content-center mt-3">
                {readOnly && (
                  <div className="col-md-6 mb-3 mb-md-3">
                    <SecondaryButton
                      type="button"
                      onClick={() => {
                        toggleReadOnly()
                        setTextCodeDisabled(false)
                      }}
                      disabled={
                        creatingEnrollment ||
                        validateLoading ||
                        validateLoading2
                      }
                    >
                      Go Back
                    </SecondaryButton>
                  </div>
                )}
                <div className="col-md-6">
                  <Button
                    type="submit"
                    onClick={form.submit}
                    disabled={
                      creatingEnrollment || validateLoading || validateLoading2
                    }
                  >
                    {readOnly ? "Enroll Now" : "Continue"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default EnrollmentForm
