import { useNavigate } from "react-router-dom"
import globals from "../../constants/globals"
import { CommonPaths, ConsumerPaths } from "../../constants/paths"
import useGetProgramHeader from "../../hooks/useGetProgramHeader"
import Button, { SecondaryButton } from "../styled/Button.styled"
import HeroSection from "../styled/HeroSection.styled"

const { PROGRAM_NAME } = globals
const { CLAIM_SALE_PATH } = ConsumerPaths
const { CURRENT_INCENTIVES_PATH } = CommonPaths

const EnrollmentSuccess = () => {
  const { header } = useGetProgramHeader()

  return (
    <>
      <HeroSection
        mutedText={PROGRAM_NAME}
        heading="You Have Been Enrolled!"
        description={header}
      />
      <NavigationLinks />
    </>
  )
}

const NavigationLinks = () => {
  const navigate = useNavigate()

  const goToClaimForm = () => navigate(CLAIM_SALE_PATH)
  const goToProductIncentive = () => navigate(CURRENT_INCENTIVES_PATH)

  return (
    <div className="container mx-auto row justify-content-center my-5">
      <div className="col-lg-8 col-11 px-3 py-4 p-md-5 border">
        <div className="row justify-content-center gap-3 gap-md-0">
          <div className="col-12 text-center mb-4">
          You can now view the current incentives and start making sales claims to earn your rewards onto your reloadable Visa Reward card account.
          </div>
          <div className="col-md-6">
            <Button type="button" onClick={goToClaimForm}>
              Make a Sales Claim
            </Button>
          </div>
          <div className="col-md-6">
            <SecondaryButton type="button" onClick={goToProductIncentive}>
              View Current Incentives
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnrollmentSuccess
