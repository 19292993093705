import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useAuthStatus } from "../hooks/useAuthStatus"
import Spinner from "./Spinner"
import { ifConsumerLoggedInOnce } from "../utils/auth"
import globals from "../constants/globals"
import { CommonPaths, ConsumerPaths } from "../constants/paths"

const { IS_ADMIN } = globals

const { SIGNIN_PATH } = CommonPaths
const { LANDING_PATH } = ConsumerPaths

function AuthorisedRoute() {
  const { loggedIn, checkingStatus } = useAuthStatus()
  const location = useLocation()

  const showLanding = !loggedIn && !ifConsumerLoggedInOnce() && !IS_ADMIN

  if (checkingStatus) {
    return <Spinner />
  }

  return (
    <>
      {loggedIn ? (
        <Outlet />
      ) : (
        <Navigate
          to={showLanding ? LANDING_PATH : SIGNIN_PATH}
          state={{ from: location }}
        />
      )}
    </>
  )
}

export default AuthorisedRoute
