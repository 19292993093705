import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import { CommonPaths } from "../constants/paths"
import Header from "../components/Layout/Header"
import { Footer } from "antd/es/layout/layout"
import Container from "../components/styled/Container.styled"
import Spinner from "../components/Spinner"
import { logout } from "../redux/auth/authSlice"
import { useAppDispatch } from "../redux/hooks"
import { removeAuthToken } from "../utils/auth"
import { resetUiState } from "../redux/ui/uiSlice"
import ReactGA from "react-ga4"

const SignOut = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  useEffect(() => {
    ReactGA.event({
      category: "Sign out",
      action: "click",
      label: "dropped",
    })
    removeAuthToken()
    dispatch(logout())
    dispatch(resetUiState())
    client.resetStore()
    navigate(CommonPaths.SIGNIN_PATH)
  }, [navigate, client, dispatch])

  return (
    <>
      <Header showNone />
      <Container>
        <Spinner />
      </Container>
      <Footer />
    </>
  )
}

export default SignOut
