import { useQuery } from "@apollo/client";
import { Divider, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import DataTable from "../../components/Layout/DataTable";
import Button from "../../components/styled/Button.styled";
import { GET_MESAAGE } from "../../graphql/queries";
import { inbox, pages } from "../../types/datatable";
import { getFormattedDate } from "../../utils/date";

type messageInbox = {
 errorMessage: (e: string) => void
}

const MessageInbox = (props: messageInbox) => {
 const pageSize = 5;
 const [currentPage, setCurrentPage] = useState<number>(1);
 const [totalPages, setTotalPages] = useState<pages>();
 const [tableData, setTableData] = useState<inbox[]>([]);
 const [open, setOpen] = useState<boolean>(false);
 const [messageById, setMessageById] = useState<any>();

 const { loading } = useQuery(GET_MESAAGE, {
  variables: {
   limit: pageSize,
   page: currentPage,
  },
  onCompleted(data) {
   if (!!data) {
    const messages = data.messagesByEnrolmentUserId.messagesWithMessageRecipient;
    setTotalPages({
     currentPage: data.messagesByEnrolmentUserId.currentPage,
     limit: pageSize,
     totalDocs: data.messagesByEnrolmentUserId.totalDocs,
     totalPages: data.messagesByEnrolmentUserId.totalPages
    })
    setTableData([]);
    messages.map((message: inbox, index: number) => {
     let order = {
      key: index,
      _id: message._id,
      createdAt: message.createdAt,
      enrolmentId: message.enrolmentId,
      isSent: message.isSent,
      sentDate: message.sentDate,
      messageBody: message.messageId?.messageBody,
      fromPhoneNumber: message.messageId?.fromPhoneNumber,
      createdMessageAt: message.messageId?.createdAt,
     }
     setTableData((oldArr: any) => [...oldArr, order])
    })
   }
  },
  onError(err) {
   props.errorMessage(err.message);
  },
  fetchPolicy: 'network-only'
 })

 const columns: ColumnsType<inbox> = [
  {
   title: "Text Messages",
   dataIndex: "fromPhoneNumber",
   key: "1",
   fixed: "left",
   width: '30%'
  },
  {
   dataIndex: "messageBody",
   key: "2",
   render: (value: string) => {
    if (!!value && value.length >= 20) {
     return `${value.substring(0, 20)}....`
    } else {
     return value
    }
   }
  },
  {
   dataIndex: "createdMessageAt",
   key: "3",
   render: (value: string) => getFormattedDate(value),
  },
 ]


 return (
  <>
   <div className="col-md-12">
    {
     <DataTable
      loading={loading}
      tableData={tableData}
      totalPages={totalPages}
      page={(cp) => {
       setCurrentPage(Number(cp))
      }}
      onRow={
       (record) => {
        if (!!record) {
         setMessageById(record)
         setOpen(true)
        }
       }
      }
      showCheck={false}
      columnData={columns}
     />
    }
   </div>
   < Modal
    centered
    open={open}
    onCancel={() => setOpen(false)}
    cancelText="close"
    footer={<div className={"w-25 ms-auto"}><Button onClick={() => setOpen(false)}>Close</Button></div>}
    width={1000}
    bodyStyle={{ height: 400, overflowX: 'auto' }}
   >
    <p>from : {messageById?.fromPhoneNumber}</p>
    <Divider />
    <p>{messageById?.messageBody}</p>
   </Modal >
  </>
 )
}

export default MessageInbox;