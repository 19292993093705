import { Dispatch, SetStateAction } from "react"
import { IAnnouncement } from "../../types/graphqlResponse"
import FileUpload from "../FileUpload"

type AnnouncementImageProps = {
  setBlockSubmit: Dispatch<SetStateAction<boolean>>
  itemToEdit?: IAnnouncement
}

const AnnouncementImage = ({
  setBlockSubmit,
  itemToEdit,
}: AnnouncementImageProps) => {
  return (
    <FileUpload
      label="Announcement Image"
      buttonLabel="Upload Image"
      setBlockSubmit={setBlockSubmit}
      rules={[
        {
          required: itemToEdit ? false : true,
          message: "Please upload an image",
        },
      ]}
      requiredMessage={itemToEdit ? undefined : "Please upload an image"}
      acceptedFormats={["image/jpeg", "image/jpg", "image/png"]}
      wrongFileFormatMessage="Please add file in accepted formats: .jpg, .jpeg and .png"
      name="image"
    />
  )
}

export default AnnouncementImage
