import Layout from "../components/Layout/Layout"
import Home from "../pages/Home"
import commonRoutes from "./CommonRoutes"
import AuthorisedRoute from "../components/AuthorisedRoute"
import { useRoutes } from "react-router-dom"
import { useMemo } from "react"
import { ConsumerPermissions } from "../constants/permissions"
import type { IRoute, IParentRoute } from "../types/route"
import { CommonPaths, ConsumerPaths } from "../constants/paths"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import { lazy } from "react"
import UpdatePassword from "../pages/UpdatePassword"
import SignIn from "../pages/SignIn"
import SignOut from "../pages/SignOut"
import EnrollmentForm from "../pages/consumer/EnrollmentForm"
import NotFound from "../pages/NotFound"
import ForgotPassword from "../pages/ForgotPassword"
import MyAccount from "../pages/consumer/MyAccount"
import MyAccountClaimTable from "../pages/consumer/MyAccountClaimTable"
import Profile from "../pages/consumer/Profile"
import Inbox from "../pages/consumer/Inbox"
import {
  ConsumerLandingPublic,
  ContactUsConsumerPublic,
  // CurrentIncentivesConsumerPublic,
  ResourcesFAQPublic,
} from "../pages/consumer/PublicPages"
import PhysicalCardRedeemed from "../pages/consumer/PhysicalCardRedeemed"
import PhysicalCardContent from "../pages/consumer/PhysicalCardContent"

const ClaimSale = lazy(() => import("../pages/consumer/ClaimSale"))

const {
  VIEW_CONSUMER_HOME,
  VIEW_CLAIM_SALE,
  VIEW_MY_CLAIMS,
  VIEW_MY_PROFILE,
  VIEW_INBOX,
} = ConsumerPermissions

const {
  CLAIM_SALE_PATH,
  MY_ACCOUNT_PATH,
  ENROLLMENT_FORM_PATH,
  MY_PROFILE_PATH,
  MY_INBOX_PATH,
  LANDING_PATH,
  QR_LANDING_PATH,
  PHYSICAL_CARD_REDEEMED,
  PHYSICAL_CARD_CONTENT,
} = ConsumerPaths

const {
  SIGNIN_PATH,
  SIGNOUT_PATH,
  UPDATE_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  HOME_PATH,
  CONTACTUS_PATH,
  CURRENT_INCENTIVES_PATH,
  RESOURCES_FAQ_PATH,
} = CommonPaths

const ConsumerRoutes = () => {
  const { permissions } = useGetMyPermissions()

  const consumerPrivateRoutes: IRoute[] = [
    {
      element: <Home />,
      path: HOME_PATH,
      permissionCode: VIEW_CONSUMER_HOME,
    },
    {
      element: <ClaimSale />,
      path: CLAIM_SALE_PATH,
      permissionCode: VIEW_CLAIM_SALE,
    },
    {
      element: (
        <MyAccount>
          <MyAccountClaimTable />
        </MyAccount>
      ),
      path: MY_ACCOUNT_PATH,
      permissionCode: VIEW_MY_CLAIMS,
    },
    {
      element: (
        <MyAccount>
          <Profile />
        </MyAccount>
      ),
      path: MY_PROFILE_PATH,
      permissionCode: VIEW_MY_PROFILE,
    },
    {
      element: (
        <MyAccount>
          <Inbox />
        </MyAccount>
      ),
      path: MY_INBOX_PATH,
      permissionCode: VIEW_INBOX,
    },
    ...commonRoutes,
  ]

  const filteredConsumerPrivateRoutes = consumerPrivateRoutes.map(
    (route: IRoute) =>
      permissions.includes(route.permissionCode)
        ? route
        : {
            ...route,
            element: <NotFound />,
            children: undefined,
          }
  )

  const consumerRoutes: IParentRoute = useMemo(
    () => [
      {
        element: <Layout />,
        children: [
          {
            element: <AuthorisedRoute />,
            children: filteredConsumerPrivateRoutes,
          },
        ],
      },
      { element: <SignOut />, path: SIGNOUT_PATH },
      { element: <SignIn />, path: SIGNIN_PATH },
      { element: <EnrollmentForm />, path: ENROLLMENT_FORM_PATH },
      { element: <ContactUsConsumerPublic />, path: CONTACTUS_PATH },
      // {
      //   element: <CurrentIncentivesConsumerPublic />,
      //   path: CURRENT_INCENTIVES_PATH,
      // },
      { element: <ResourcesFAQPublic />, path: RESOURCES_FAQ_PATH },
      { element: <ConsumerLandingPublic />, path: LANDING_PATH },
      { element: <ConsumerLandingPublic />, path: QR_LANDING_PATH },
      { element: <UpdatePassword />, path: UPDATE_PASSWORD_PATH },
      { element: <ForgotPassword />, path: FORGOT_PASSWORD_PATH },
      { element: <PhysicalCardContent />, path: PHYSICAL_CARD_CONTENT },
      { element: <PhysicalCardRedeemed />, path: PHYSICAL_CARD_REDEEMED },
      { element: <NotFound />, path: "*" },
    ],
    [filteredConsumerPrivateRoutes]
  )

  return useRoutes(consumerRoutes)
}

export default ConsumerRoutes
