import { useQuery } from "@apollo/client";
import { Divider, Modal, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { GET_EMAIL_BY_ID } from "../../graphql/queries";
import { inbox, pages } from "../../types/datatable";
import DataTable from "../../components/Layout/DataTable";
import { getFormattedDate } from "../../utils/date";
import Button from "../../components/styled/Button.styled";
import styled from "styled-components";
import globals from "../../constants/globals";
import { DownloadOutlined } from '@ant-design/icons';
import HTMLReactParser from "html-react-parser";
import { getStringFromHTML } from "../../utils/general";

const OnHover = styled.a`
&:hover{
 outline:none;
 text-decoration:none;
}
&:hover div{
 color: ${globals.PRIMARY_COLOR};
 background-color:${globals.SECONDARY_ANCHOR_BG_COLOR};
 padding: 0 7px;
 border-radius: 5px;
}
`

type emailInbox = {
    errorMessage: (e: string) => void
}

const EmailInbox = (props: emailInbox) => {

    const pageSize = 5;
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [tableData, setTableData] = useState<inbox[]>([]);
    const [totalPages, setTotalPages] = useState<pages>();
    const [open, setOpen] = useState<boolean>(false);
    const [emailById, setEmailById] = useState<any>();

    const { loading } = useQuery(GET_EMAIL_BY_ID, {
        variables: {
            limit: pageSize,
            page: currentPage,
        },
        onCompleted(data) {
            if (!!data) {
                const emails = data.emailsByEnrolmentUserId.emailsWithEmailRecipient;
                setTotalPages({
                    currentPage: data.emailsByEnrolmentUserId.currentPage,
                    limit: pageSize,
                    totalDocs: data.emailsByEnrolmentUserId.totalDocs,
                    totalPages: data.emailsByEnrolmentUserId.totalPages
                })
                setTableData([]);
                emails.map((email: inbox, index: number) => {
                    let order = {
                        key: index,
                        _id: email._id,
                        createdAt: email.createdAt,
                        enrolmentId: email.enrolmentId,
                        isSent: email.isSent,
                        sentDate: email.sentDate,
                        messageBody: email.emailId?.messageBody,
                        fromAddress: email.emailId?.fromAddress,
                        subject: email.emailId?.subject,
                        createdEmailAt: email.emailId?.createdAt,
                        attachments: email.emailId?.attachmentsUrls
                    }
                    setTableData((oldArr: any) => [...oldArr, order])
                })
            }
        },
        onError(err) {
            props.errorMessage(err.message);
        },
        fetchPolicy: 'network-only'
    })

    const columns: ColumnsType<inbox> = [
        {
            title: "Emails",
            dataIndex: "fromAddress",
            key: "1",
            fixed: "left",
            width: '30%'
        },
        {
            dataIndex: "subject",
            key: "2",
        },
        {
            dataIndex: "messageBody",
            key: "3",
            render: (value) => {
                let result = getStringFromHTML(value)
                if (!!result && result.length >= 20) {
                    return `${result.substring(0, 20)}....`
                } else {
                    return result
                }
            }
        },
        {
            dataIndex: "createdEmailAt",
            key: "4",
            render: (value) => getFormattedDate(value),
        },
    ]


    return (
        <>
            <div className="col-md-12">
                {
                    <DataTable
                        loading={loading}
                        tableData={tableData}
                        totalPages={totalPages}
                        page={(cp) => {
                            setCurrentPage(Number(cp))
                        }}
                        onRow={
                            (record) => {
                                if (!!record) {
                                    setEmailById(record)
                                    setOpen(true)
                                }
                            }
                        }
                        showCheck={false}
                        columnData={columns}
                    />
                }
            </div>
            < Modal
                centered
                title={`Subject : ${emailById?.subject}`}
                open={open}
                onCancel={() => setOpen(false)}
                cancelText="close"
                footer={<div className={"w-25 ms-auto"}><Button onClick={() => setOpen(false)}>Close</Button></div>}
                width={1000}
                bodyStyle={{ height: 400, overflowX: 'auto' }}
            >
                <p>from : {emailById?.fromAddress}</p>
                <Divider />
                <p>{!!emailById ? HTMLReactParser(emailById?.messageBody) : null}</p>
                <Divider />
                <div className="col-md-12 d-flex">
                    {emailById?.attachments.map((url: string) => (
                        <OnHover href={url} download rel="noreferrer" className="custom-link ms-4 d-block" style={{ textDecoration: 'none', outline: 'none' }} >
                            <Typography.Paragraph ellipsis={true} className="my-auto">
                                {url.split(".com/")[1].split("?")[0]}
                                <DownloadOutlined className="fs-4 ms-2 mt-1" />
                            </Typography.Paragraph>
                        </OnHover>
                    ))}
                </div>
            </Modal >
        </>
    )

}

export default EmailInbox;