import { Link } from "./CustomLink"
import { AdminPaths } from "../../constants/paths"
import { AdminPermissions } from "../../constants/permissions"
import DropdownNavbar from "./DropdownNavbar"

const { VIEW_PROGRAM_COMMUNICATIONS, VIEW_ANNOUNCEMENT } = AdminPermissions
const { PROGRAM_COMMUNICATIONS_PATH, ANNOUNCEMENT_PATH } = AdminPaths

const items = [
  {
    label: <Link to={PROGRAM_COMMUNICATIONS_PATH}>Program Communications</Link>,
    permission: VIEW_PROGRAM_COMMUNICATIONS,
    key: "1",
  },
  {
    label: <Link to={ANNOUNCEMENT_PATH}>Participant Site Announcements</Link>,
    permission: VIEW_ANNOUNCEMENT,
    key: "2",
  },
]

type CommunicationsNavItemProps = {
  toggleCollapse: () => void
}

const CommunicationsNavItem = ({
  toggleCollapse,
}: CommunicationsNavItemProps) => {
  return (
    <DropdownNavbar
      label="Communications"
      items={items}
      toggleCollapse={toggleCollapse}
    />
  )
}

export default CommunicationsNavItem
