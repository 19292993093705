import { useMutation, useSubscription } from "@apollo/client"
import { Alert, UploadProps } from "antd"
import { Upload } from "antd"
import { useState } from "react"
import { PRODUCT_UPLOAD } from "../../graphql/mutations"
import { PRODUCT_UPLOAD_SUBSCRIPTION } from "../../graphql/subscriptions"
import type { UploadChangeParam } from "antd/es/upload"
import { Heading } from "../../components/styled/Heading"
import Spinner from "../../components/Spinner"
import Container from "../../components/styled/Container.styled"
import { SecondaryButton } from "../../components/styled/Button.styled"

//const { PRODUCT_UPLOADER_SAMPLE } = globals
const xlsxFileMimeType: string =
 "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

const ProductUploader = () => {
 const [beforeUploadError, setBeforeUploadError] = useState<string>("")
 const [showSubscriptionMessage, setShowSubscriptionMessage] =
  useState<boolean>(false)

 const [productUpload, { loading: uploading }] = useMutation(PRODUCT_UPLOAD)

 const { data: subscriptionResponse } = useSubscription(
  PRODUCT_UPLOAD_SUBSCRIPTION
 )

 const uploadProps: UploadProps = {
  name: "file",
  maxCount: 1,
  accept: xlsxFileMimeType,
  beforeUpload: () => false,
  onChange: (file: UploadChangeParam) => {
   if (validateFile(file)) {
    setShowSubscriptionMessage(() => true)
    uploadFile(file)
   }
  },
 }

 const validateFile = (file: UploadChangeParam): boolean | void => {
  setShowSubscriptionMessage(() => false)
  // Check if file was removed
  if (file.file.status === "removed") {
   return setBeforeUploadError("")
  }
  // Check for file size
  const fileSizeGreaterThanTen: boolean =
   file?.file && file.file.size ? file.file.size / 1024 / 1024 > 10 : false
  if (fileSizeGreaterThanTen) {
   return setBeforeUploadError("Please add a file less than 10 MB")
  }
  // Check for file type
  if (file.file.type !== xlsxFileMimeType) {
   return setBeforeUploadError("Please add .xlsx file only")
  }
  setBeforeUploadError("")
  return true
 }

 const uploadFile = (file: UploadChangeParam): void => {
  productUpload({
   variables: {
    file: file.file,
   },
  })
 }

 return (
  <Container>
   <div className="row justify-content-center">
    <div className="col-10 col-md-8">
     <Heading heading="Upload Products" render={<SampleFile />} />
    </div>
   </div>
   <div className="row justify-content-center">
    <div className="col-10 col-md-8">
     <Upload {...uploadProps}>
      <SecondaryButton type="button">Upload File</SecondaryButton>
     </Upload>
     <div className="mb-3"></div> {/* Space for Loader and Error*/}
     <div className="col">{uploading && <Spinner />}</div>
     {!!beforeUploadError && (
      <Alert type="error" message={beforeUploadError} />
     )}
     {!!subscriptionResponse && showSubscriptionMessage && (
      <Alert
       type={subscriptionResponse.productFileUpdateStatus.type}
       message={subscriptionResponse.productFileUpdateStatus.message}
      />
     )}
    </div>
   </div>
  </Container>
 )
}

const SampleFile = () => (
 <div className="d-flex h-100 justify-content-center align-items-end">
  {/* <a href={PRODUCT_UPLOADER_SAMPLE} className="custom-link">
   Download Sample File
  </a> */}
 </div>
)

export default ProductUploader
