import { useState } from "react"
import { Checkbox } from "antd"
import { checkBoxProp } from "../../../types/reports"
import type { CheckboxChangeEvent } from "antd/es/checkbox"

const CheckReport = (props: checkBoxProp) => {
  const [checkedAll, setCheckedAll] = useState(props.checkBoxList)

  const onChange = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target
    if (value === "selectAll") {
      if (checked) {
        let selectAll = checkedAll.map((check) => {
          return { ...check, isChecked: checked }
        })
        setCheckedAll(selectAll)
        let selectedValArr = selectAll.map((check) => check.value)
        props.selected(selectedValArr)
      } else {
        let disSelectAll = checkedAll.map((check) => {
          return { ...check, isChecked: false }
        })

        let removeSelection = disSelectAll
          .filter((check) => check.isChecked === true)
          .map((select) => {
            return { ...select }
          })

        setCheckedAll(disSelectAll)
        props.selected(removeSelection)
      }
    } else {
      let singleSelect = checkedAll.map((check) => {
        return check.value === value ? { ...check, isChecked: checked } : check
      })
      let getSingleSelect = singleSelect.filter((check) => {
        return !!check.isChecked ? check.value : null
      })

      let getSingleSelectValue = getSingleSelect.map((check) => {
        return check.value
      })

      setCheckedAll(singleSelect)
      props.selected(getSingleSelectValue)
    }
  }

  return (
    <>
      <Checkbox
        onChange={onChange}
        value={"selectAll"}
        checked={
          checkedAll.filter((checkbox) => checkbox?.isChecked !== true).length <
          1
        }
        className="mb-3"
      >
        Select All
      </Checkbox>

      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 gy-3">
        {checkedAll.map((checkList, index) => {
          return (
            <div className="col">
              <Checkbox
                className="mx-0"
                onChange={onChange}
                checked={checkList?.isChecked || undefined}
                value={checkList.value}
                key={index}
              >
                {checkList.label}
              </Checkbox>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default CheckReport
