import { useMutation, useQuery } from "@apollo/client"
import { Alert, Checkbox, Form, Popconfirm } from "antd"
import { useEffect, useState } from "react"
import Address2 from "../../components/EnrollmentForm/Address2"
import DealerAddressAutocomplete from "../../components/EnrollmentForm/AddressAutocomplete"
import City from "../../components/EnrollmentForm/City"
import LegalFirstName from "../../components/EnrollmentForm/LegalFirstName"
import LegalLastName from "../../components/EnrollmentForm/LegalLastName"
import State from "../../components/EnrollmentForm/State"
import WorkEmail from "../../components/EnrollmentForm/WorkEmail"
import ZIP from "../../components/EnrollmentForm/ZIP"
import Spinner from "../../components/Spinner"
import Button, { SecondaryButton } from "../../components/styled/Button.styled"
import {
  UPDATE_PROFILE,
  VALIDATE_ADDRESS,
  VALIDATE_ENROLLMENT_FORM,
} from "../../graphql/mutations"
import { GET_MY_PROFILE_DETAILS, GET_USER_STAT } from "../../graphql/queries"
import { IGetMyProfileDetailsResponse } from "../../types/graphqlResponse"
import PasswordToConfirmChanges from "../../components/Form/PasswordToConfirmChanges"
import RESET_ALERT from "../../constants/resetAlert"
import { ValidateAddressInput } from "../../types/apiInputTypes"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setVerifiedPhones } from "../../redux/ui/uiSlice"
import PhoneNumber from "../../components/EnrollmentForm/PhoneNumber"

const Profile = () => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [readOnly, setReadOnly] = useState(false)
  const [editable, setEditable] = useState(false)
  const [alert, setAlert] = useState(RESET_ALERT)

  const [checked, setChecked] = useState(false)
  const [checkPhoneVeification, setCheckPhoneVeification] =
    useState<boolean>(false)
  const [textCodeDisabled, setTextCodeDisabled] = useState<boolean>(true)
  const [verified, setVerified] = useState<boolean>(false)
  const [phoneNumberVerification, setPhoneNumberVerification] =
    useState<boolean>(false)

  // State for verified phones
  const { verifiedPhones } = useAppSelector((state) => state.ui)

  const toggleReadOnly = (): void => setReadOnly((val) => !val)
  const toggleEditable = (): void => setEditable((val) => !val)
  const onCancelEdit = () => {
    toggleEditable()
    setReadOnly(false)
    setAlert(RESET_ALERT)
    form.setFieldsValue({
      ...data?.enrolmentByUserID,
    })
  }

  // Query for getting profile details
  const {
    data,
    loading: loadingDetails,
    refetch,
  } = useQuery<IGetMyProfileDetailsResponse>(GET_MY_PROFILE_DETAILS, {
    onCompleted(data) {
      // Setting form values after getting details
      form.setFieldsValue({
        ...data.enrolmentByUserID,
      })
      setAlert(RESET_ALERT)
      dispatch(setVerifiedPhones([data.enrolmentByUserID.phone]))
    },
    onError(error) {
      setAlert({ type: "error", message: error.message })
    },
    fetchPolicy: "no-cache",
  })

  // Mutation for updating profile details
  const [updateProfile, { loading: updatingProfile }] =
    useMutation(UPDATE_PROFILE)
  // Mutation for validating profile details
  const [validateEnrollment, { loading: validateLoading }] = useMutation(
    VALIDATE_ENROLLMENT_FORM
  )
  // Mutation for validating addresses
  const [validateAddress, { loading: validateLoading2 }] = useMutation<{
    validateAddress: "true" | "false"
  }>(VALIDATE_ADDRESS)

  const validateAndToggleReadOnly = (
    workEmail: string,
    address: ValidateAddressInput
  ) => {
    // Validate Personal Address
    validateAddress({
      variables: address,
      onCompleted: (response) => {
        if (response.validateAddress === "true") {
          // Checking if the workEmail has changed
          if (
            data?.enrolmentByUserID.workEmail === workEmail.trim().toLowerCase()
          ) {
            toggleReadOnly()
          } else {
            // Validate workEmail if it has changed
            validateEnrollment({
              variables: { workEmail },
              onCompleted: () => {
                window.scrollTo(0, 0)
                toggleReadOnly()
              },
              onError(error) {
                setAlert({ type: "error", message: error.message })
              },
            })
          }
          // Throwing error if validateAddress is not successful
        } else if (response.validateAddress === "false") {
          setAlert({ type: "error", message: "Please enter correct address" })
        }
      },
      onError(error) {
        setAlert({ type: "error", message: error.message })
      },
    })
  }

  const onFinish = (values: any) => {
    // Checking if the phone number is verified
    // If the SMS is opted in
    if (smsOptedIn && !verifiedPhones?.includes(values.phone)) {
      setAlert({ type: "error", message: "Please verify your phone number" })
      return
    }

    setAlert(RESET_ALERT)

    const personalAddressVariables = {
      dealerStreetAddress: values.street1,
      dealerCity: values.city,
      dealerState: values.state,
      dealerZip: values.zip,
      secondary: values.street2,
    }

    if (!readOnly) {
      return validateAndToggleReadOnly(
        values.workEmail,
        personalAddressVariables
      )
    }

    updateProfile({
      variables: {
        profile: {
          ...values,
          smsOptIn: checked,
          enrolmentID: data?.enrolmentByUserID._id,
        },
      },
      onCompleted: () => {
        toggleReadOnly()
        toggleEditable()
        refetch()
        // Resetting Password after completion
        form.setFieldValue("pwd", "")
      },
      refetchQueries: [{ query: GET_USER_STAT }, "UserStats"],
      onError(error) {
        setAlert({ type: "error", message: error.message })
      },
    })
  }

  const loading =
    loadingDetails || updatingProfile || validateLoading || validateLoading2

  const smsOptedIn = data?.enrolmentByUserID.smsOptIn

  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        setAlert(RESET_ALERT)
      }, 3000)
    }
  }, [alert.message])

  return (
    <div className="container-fluid pt-3 pb-5">
      <div className="row justify-content-center">
        <div className="col-12 border-bottom mb-3">
          <div className="row mb-1">
            <div className="col-12 col-md">
              <h3>My Information</h3>
            </div>
            <div className="col-12 col-md-4">
              {/* Edit/Cancel Edit Profile Buttons */}
              {editable ? (
                <Popconfirm
                  title="Are you sure you want to cancel?"
                  description="You will lose any unsaved changes"
                  onConfirm={onCancelEdit}
                  okText="Yes"
                  okButtonProps={{ danger: true }}
                  cancelText="No"
                >
                  <SecondaryButton type="button">Cancel</SecondaryButton>
                </Popconfirm>
              ) : (
                <Button type="button" onClick={toggleEditable}>
                  Edit
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        disabled={readOnly || !editable}
        requiredMark={false}
      >
        <div className="row">
          {[
            <LegalFirstName disabled />,
            <LegalLastName disabled />,
            <WorkEmail disabled />,
            <>
              {smsOptedIn && editable ? (
                <div className="row align-items-center">
                  <div className="col-7 col-lg-8">
                    <PhoneNumber
                      phoneVerification={phoneNumberVerification}
                      checkPhoneVeification={checkPhoneVeification}
                      setTextCodeDisabled={(state) => {
                        setTextCodeDisabled(state)
                        setPhoneNumberVerification(false)
                        !!state
                          ? form.setFields([{ name: "phone", errors: [] }])
                          : form.setFields([{ name: "phone", errors: [] }])
                      }}
                      setVerified={(state) => {
                        setVerified(state)
                      }}
                    />
                    ,
                  </div>
                </div>
              ) : (
                <PhoneNumber
                  phoneVerification={phoneNumberVerification}
                  checkPhoneVeification={checkPhoneVeification}
                  setTextCodeDisabled={(state) => {
                    setTextCodeDisabled(state)
                    setPhoneNumberVerification(false)
                    !!state
                      ? form.setFields([{ name: "phone", errors: [] }])
                      : form.setFields([{ name: "phone", errors: [] }])
                  }}
                  setVerified={(state) => {
                    setVerified(state)
                  }}
                />
              )}
            </>,
            // For Address 1
            <DealerAddressAutocomplete
              form={form}
              name="street1"
              label="Address 1"
              formItemNamesToUpdate={["street2", "city", "state", "zip"]}
              requiredMessage="Please enter an address"
              disabled={readOnly || !editable}
            />,
            <Address2 />,
            <City />,
            // State & ZIP combo
            <div className="row">
              <div className="col">
                <State />
              </div>
              <div className="col">
                <ZIP />
              </div>
            </div>,

            //sms opt in
            <div className="row my-3">
              <div className="col-md-9">
                <Checkbox
                  onChange={(e) => {
                    setChecked(e.target.checked)
                    if (verified === false) {
                      setCheckPhoneVeification(e.target.checked)
                    }
                    e.target.checked === true &&
                    textCodeDisabled === false &&
                    verified === false
                      ? form.setFields([
                          {
                            name: "phone",
                            errors: ["Please verify phone number"],
                          },
                        ])
                      : form.setFields([{ name: "phone", errors: [] }])
                  }}
                  checked={checked || smsOptedIn}
                  disabled={readOnly || !editable}
                  //disabled={textCodeDisabled}
                >
                  I want to receive SMS messages regarding this program
                </Checkbox>
              </div>
              <div className="col-md-3">
                <Button
                  type="button"
                  onClick={() => {
                    setPhoneNumberVerification(true)
                  }}
                  disabled={!editable}
                >
                  Text Code
                </Button>
              </div>
            </div>,
          ].map((item, index) => (
            <div className="col-sm-6" key={index}>
              {item}
            </div>
          ))}
          {readOnly ? <PasswordToConfirmChanges /> : <></>}
        </div>
      </Form>

      {/* Spinner */}
      {loading && <Spinner />}

      {/* Alert Messages */}
      {alert.message && <Alert type={alert.type} message={alert.message} />}

      {/* Save & Go Back Buttons */}
      {editable && (
        <div className="row justify-content-center pt-3">
          {readOnly && (
            <div className="col-md-6 mb-3 mb-md-3">
              <SecondaryButton
                type="button"
                onClick={() => {
                  toggleReadOnly()
                  setAlert(RESET_ALERT)
                }}
                disabled={loading}
              >
                Go Back
              </SecondaryButton>
            </div>
          )}
          <div className="col-md-6">
            <Button type="submit" onClick={form.submit} disabled={loading}>
              Save Changes
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Profile
