import { ReactNode, Suspense, lazy } from "react"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import Container from "../../components/styled/Container.styled"
import Spinner from "../../components/Spinner"

const ContactUs = lazy(() => import("../ContactUs"))
const CurrentIncentives = lazy(() => import("../CurrentIncentives"))
const ResourcesFAQ = lazy(() => import("../ResourcesFAQ"))
const ConsumerLanding = lazy(() =>
  import("./Landing").then((module) => ({
    default: module.ConsumerLanding,
  }))
)

const Layout = ({ children }: { children: ReactNode }) => {
  const { loggedIn } = useAuthStatus()

  return (
    <>
      <Header ifConsumerNotLoggedIn={!loggedIn} />
      <Suspense
        fallback={
          <Container>
            <Spinner />
          </Container>
        }
      >
        {children}
      </Suspense>
      <Footer />
    </>
  )
}

const ContactUsConsumerPublic = () => {
  return (
    <Layout>
      <ContactUs />
    </Layout>
  )
}

const CurrentIncentivesConsumerPublic = () => {
  return (
    <Layout>
      <CurrentIncentives />
    </Layout>
  )
}

const ResourcesFAQPublic = () => {
  return (
    <Layout>
      <ResourcesFAQ />
    </Layout>
  )
}

const ConsumerLandingPublic = () => {
  return (
    <Layout>
      <ConsumerLanding />
    </Layout>
  )
}

export {
  ContactUsConsumerPublic,
  CurrentIncentivesConsumerPublic,
  ResourcesFAQPublic,
  ConsumerLandingPublic,
}
