import { Spin, Form, Input } from "antd"
import { SIGNIN } from "../graphql/mutations"
import { useMutation } from "@apollo/client"
import { useNavigate, useLocation, Link } from "react-router-dom"
import Button from "../components/styled/Button.styled"
import { useAuthStatus } from "../hooks/useAuthStatus"
import { useEffect } from "react"
import globals from "../constants/globals"
import { ISigninResponse } from "../types/graphqlResponse"
import { setAuthToken, setIfConsumerLoggedInOnce } from "../utils/auth"
import useGetMyPermissions from "../hooks/useGetMyPermissions"
import { CommonPaths, ConsumerPaths } from "../constants/paths"
import heroImage from "../assets/images/HeaderImage.jpg"
import styled from "styled-components"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"
import { login, setTokenTimeout } from "../redux/auth/authSlice"
import { useAppDispatch } from "../redux/hooks"
import ReactGA from "react-ga4"
import { useSsoSignIn } from "../hooks/useSsoSignIn"

const { IS_ADMIN, PROGRAM_NAME } = globals

const SignInHeroContainer = styled.div`
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
    max-height: 30vh;
  }

  & > div {
    text-align: center;
    background-color: hsla(0, 100%, 0%, 0.6);
    padding: 3rem;
    color: #fff;
    min-width: 100%;

    @media screen and (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 45%;
    }

    @media screen and (min-width: 992px) {
      max-width: 55%;
    }
  }
`

const Muted = styled.span`
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
`

const Heading = styled.h1`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3rem;
  text-align: center;
`

const SignIn = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { loggedIn } = useAuthStatus()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : CommonPaths.HOME_PATH

  const [signinUser, { loading, error }] = useMutation<ISigninResponse>(SIGNIN)
  const { loading: loadingPermissions } = useGetMyPermissions()

  const signIn = (values: { userName: string; password: string }) => {
    signinUser({
      variables: {
        ...values,
        signingInFromConsumerOrAdmin: IS_ADMIN ? "admin" : "consumer",
      },
      onCompleted: (data) => {
        setAuthToken({
          token: data.signIn.token,
          refresh_token: data.signIn.refreshToken,
        })
        dispatch(setTokenTimeout(data.signIn.tokenTime))
        dispatch(login())
        setIfConsumerLoggedInOnce()
        ReactGA.event({
          category: "Sign In",
          action: "click",
          label: "Sigined In",
        })
      },
      fetchPolicy: "network-only",
    })
  }

  useSsoSignIn()

  useEffect(() => {
    if (loggedIn && !loadingPermissions) {
      navigate(from, { replace: true })
    }
  }, [from, navigate, loggedIn, loadingPermissions])

  return (
    <>
      <Header ifConsumerNotLoggedIn={!IS_ADMIN} />
      <SignInHeroContainer>
        <img src={heroImage} alt="Cover" />

        <div>
          <Muted>{PROGRAM_NAME} Program</Muted>
        </div>
      </SignInHeroContainer>

      <div className="container-fluid py-5">
        <Heading>Sign In</Heading>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 col-10 border p-4">
            <Form onFinish={signIn} layout="vertical" requiredMark={false}>
              <Form.Item
                name="userName"
                label="Username"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              {loading ? (
                <div className="row justify-content-center pt-1">
                  <div className="col text-center">
                    <Spin />
                  </div>
                </div>
              ) : (
                error && (
                  <div className="row pt-1">
                    <div className="col">
                      <div className="alert alert-danger">{error?.message}</div>
                    </div>
                  </div>
                )
              )}
              <div className="row pt-3 text-center">
                <div className="col">
                  <Button type="submit">Sign In</Button>
                </div>
              </div>
            </Form>

            <div className="row pt-4 text-center text-md-start">
              <div className="col-md-6">
                {!IS_ADMIN && (
                  <Link
                    to={ConsumerPaths.ENROLLMENT_FORM_PATH}
                    className="custom-link"
                  >
                    Enroll Now
                  </Link>
                )}
              </div>
              <div className="col-md-6 text-center text-md-end">
                <Link
                  to={CommonPaths.FORGOT_PASSWORD_PATH}
                  className="custom-link"
                >
                  Forgot Password
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SignIn
