import { useState } from "react"
import { useLazyQuery } from "@apollo/client"
import { Form, DatePicker, Alert, Divider, Select } from "antd"
import CheckReport from "./checkboxReport"
import { reportProp } from "../../../types/reports"
import { Heading } from "../../../components/styled/Heading"
import Button from "../../../components/styled/Button.styled"
import Container from "../../../components/styled/Container.styled"
import { dateFormat } from "../../../constants/general"
import { getDate } from "../../../utils/date"
import { Dayjs } from "dayjs"
import { claimStatusIterable } from "../../../constants/claimsStatus"

const Reports = ({
  title,
  label,
  showDateRange = true,
  ...props
}: reportProp) => {
  const mode = "REPORT"
  const limit = 100000000
  const isClaimReport = title === "Claim Report"
  const [startDate, setStartDate] = useState<Dayjs | null>()
  const [endDate, setEndDate] = useState<Dayjs | null>()
  const [selected, setSelected] = useState<Array<Object>>([])
  const [alertMessage, setAlertMessage] = useState<JSX.Element>()
  const [status, setStatus] = useState<string>()

  const [sendReport] = useLazyQuery(props.query, {
    onCompleted(data) {
      setAlertMessage(() => {
        return (
          <Alert type="success" message={`Report generated successfully`} />
        )
      })
      resetState()

      setTimeout(() => {
        let url
        if (!!data.listClaimsWithPagination) {
          url = data.listClaimsWithPagination.url
        } else if (!!data.changeAddressReport) {
          url = data.changeAddressReport.url
        } else {
          url = data.enrolments.url
        }
        window.location.replace(url)
      }, 2000)

      setTimeout(() => {
        resetState()
      }, 6000)
    },
    onError(err) {
      setAlertMessage(() => {
        return <Alert type="error" message={err.message}></Alert>
      })
      resetState()
    },
  })

  const resetState = () => {
    setTimeout(() => {
      setAlertMessage(<></>)
    }, 3000)
  }

  const onFinish = async () => {
    if (selected.length === 0) {
      setAlertMessage(
        <Alert
          type="info"
          message="Please select at least one field before exporting."
        />
      )
      resetState()
      return
    }

    sendReport({
      variables: {
        fields: selected,
        startDate: startDate ? getDate(startDate) : undefined,
        endDate: endDate ? getDate(endDate) : undefined,
        mode,
        limit,
        status: status === "All" ? undefined : status,
      },
    })
  }

  const getSelectedHandler = (data: Array<Object>) => {
    setSelected(data)
  }

  return (
    <Container>
      <Heading
        heading={title}
        description="Reports are produced and downloaded as excel files"
      />

      <div className="row mb-3">
        <div className="col">{alertMessage}</div>
      </div>

      <Form name="report" onFinish={onFinish} layout="vertical">
        <div className="row px-0 py-4 border">
          <div className="col-12 px-4">
            <CheckReport
              checkBoxList={props.checkBoxList}
              selected={getSelectedHandler}
            />
          </div>

          {/* Date Range Selection */}
          {showDateRange && (
            <div className="col-12 bg-light border-bottom mt-4 px-4">
              {!!label && (
                <div className="row pt-3">
                  <div className="col">
                    <strong>{label}</strong>
                  </div>
                </div>
              )}

              <div className="row mt-4">
                <div className="col-md-6 col-12">
                  <Form.Item label="Start Date">
                    <DatePicker
                      onChange={(date) => setStartDate(date)}
                      disabledDate={(d) => d.isAfter(endDate)}
                      className="w-100"
                      format={dateFormat}
                      placeholder={dateFormat}
                    />
                  </Form.Item>
                </div>
                <div className="col-md-6 col-12">
                  <Form.Item label="End Date">
                    <DatePicker
                      onChange={(date) => setEndDate(date)}
                      disabledDate={(d) => d.isBefore(startDate)}
                      className="w-100"
                      format={dateFormat}
                      placeholder={dateFormat}
                    />
                  </Form.Item>
                </div>
                {isClaimReport && (
                  <div>
                    <div className="row pt-3 mb-3">
                      <div className="col">
                        <strong>Claim Status:</strong>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 pe-2">
                      <Select
                        className="w-100 mb-4"
                        onChange={(e) => setStatus(e)}
                        options={claimStatusIterable}
                      />
                    </div>
                    <div className="col-md-6 col-12"></div>
                  </div>
                )}
              </div>
            </div>
          )}

          <Divider />

          <div className="row justify-content-center">
            <div className="col-6 col-md-4">
              <Button type="submit">Export Report</Button>
            </div>
          </div>
        </div>
      </Form>
    </Container>
  )
}

export default Reports
