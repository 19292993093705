import { Form, Input } from "antd"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

const GoesByName = () => {
  return (
    <Form.Item
      name="goesByName"
      label="Goes By Name"
      rules={[validateLeadingTrailingSpaces()]}
    >
      <Input autoComplete="new-password" />
    </Form.Item>
  )
}

export default GoesByName
