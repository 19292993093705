import { Link, useLocation, useNavigate } from "react-router-dom"
import Button from "../components/styled/Button.styled"
import ProgramLogo from "../assets/images/ProgramLogo.png"
import Footer from "../components/Layout/Footer"
import { AdminPaths, CommonPaths, ConsumerPaths } from "../constants/paths"
import { useAuthStatus } from "../hooks/useAuthStatus"
import { useEffect } from "react"

const NotFound = () => {
  const { loggedIn } = useAuthStatus()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const adminPaths = Object.values(AdminPaths) as string[]
  const consumerPaths = Object.values(ConsumerPaths) as string[]
  const commonPaths = Object.values(CommonPaths) as string[]
  const allPaths = [...adminPaths, ...consumerPaths, ...commonPaths]
  const isPathnameValid = allPaths.includes(pathname || pathname.slice(0, -1))

  useEffect(() => {
    if (pathname === CommonPaths.HOME_PATH) {
      navigate(CommonPaths.SIGNIN_PATH)
    }
  }, [pathname, navigate])

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={ProgramLogo}
              height="60"
              className="d-inline-block align-top"
              alt="Program logo"
            />
          </Link>
        </div>
      </nav>

      <div className="container-fluid py-5 vh-150">
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 col-10 border p-5 text-center">
            {isPathnameValid ? (
              <>
                <h1 className="mb-5">Page Not Accessible</h1>
                {loggedIn ? <GoHomeButton /> : <SignInButton />}
              </>
            ) : (
              <>
                <h1 className="mb-5">Page Not Found</h1>
                <GoHomeButton />
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const GoHomeButton = () => (
  <Link to={CommonPaths.HOME_PATH}>
    <Button type="button">Go Home</Button>
  </Link>
)

const SignInButton = () => (
  <Link to={CommonPaths.SIGNIN_PATH}>
    <Button type="button">Please Sign In To Access</Button>
  </Link>
)

export default NotFound
