const authTokenKey = "auth_token"
const refreshTokenKey = "refresh_token"

const getAuthToken = (): string | null => localStorage.getItem(authTokenKey)
const getRefreshToken = (): string | null =>
  localStorage.getItem(refreshTokenKey)
const removeAuthToken = (): void => {
  localStorage.removeItem(authTokenKey)
  localStorage.removeItem(refreshTokenKey)
}
const INVALID_SESSION_TOKEN = "INVALID_SESSION_TOKEN"

const setAuthToken = ({
  token,
  refresh_token,
}: {
  token: string
  refresh_token: string
}): void => {
  localStorage.setItem(authTokenKey, token)
  localStorage.setItem(refreshTokenKey, refresh_token)
}

const LOGGED_IN_ONCE = "logged_in_once"
/**
 * If the consumer has logged in ever once, then
 * we want them to be redirected to the login page,
 * instead of the landing page.
 */
const setIfConsumerLoggedInOnce = () => {
  localStorage.setItem(LOGGED_IN_ONCE, "true")
}
const ifConsumerLoggedInOnce = () => !!localStorage.getItem(LOGGED_IN_ONCE)

export {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  getRefreshToken,
  INVALID_SESSION_TOKEN,
  authTokenKey,
  setIfConsumerLoggedInOnce,
  ifConsumerLoggedInOnce,
}
