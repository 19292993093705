import { useQuery } from "@apollo/client"
import { Form, FormInstance, Select } from "antd"
import { useState } from "react"
import { GET_DEALERS } from "../../graphql/queries"
import { IGetDealersResponse } from "../../types/graphqlResponse"
import useDebouncedValue from "../../hooks/useDebouncedValue"
import Spinner from "../Spinner"

type Props = {
  form: FormInstance
}

const OPTIONS_LIMIT = 50

const DealerNameExisting = ({ form }: Props) => {
  const [searchTerm, setSearchTerm] = useState("")

  /** Dealer Options has been given three key value
   * pairs. 'label' is the one user sees, 'value' is ID
   * and it is sent to backend, 'name' is shop name
   * on which the search works
   */

  const [dealerOptions, setDealerOptions] = useState<
    { value: string; label: string; name: string }[]
  >([])

  const { debouncedValue, debouncing } = useDebouncedValue(searchTerm)

  const { data: allDealers, loading } = useQuery<IGetDealersResponse>(
    GET_DEALERS,
    {
      variables: {
        shopName: debouncedValue,
        limit: OPTIONS_LIMIT,
        page: 1,
      },
      onCompleted: (data): void => {
        const mappedData = data.dealersSearchWithPagination.dealers.map(
          (dealer) => ({
            label: `${dealer.shopName} - ${dealer.streetAddress}, ${dealer.city}, ${dealer.state}`,
            name: dealer.shopName,
            value: dealer._id,
          })
        )
        setDealerOptions(mappedData)
      },
    }
  )

  const setFormValues = (value: string) => {
    const selectedDealer = allDealers?.dealersSearchWithPagination.dealers.find(
      (dealer) => dealer._id === value
    )
    form.setFieldsValue({
      dealerStreetAddress: selectedDealer?.streetAddress,
      dealerState: selectedDealer?.state,
      dealerCity: selectedDealer?.city,
      dealerZip: selectedDealer?.zip,
    })
    form.validateFields([
      "dealerStreetAddress",
      "dealerState",
      "dealerCity",
      "dealerZip",
    ])
  }

  return (
    <Form.Item
      label="Dealer Name"
      name="dealerId"
      rules={[
        {
          required: true,
          message: "Please enter the dealer name",
        },
      ]}
    >
      <Select
        showSearch
        filterOption={false}
        options={dealerOptions}
        onSelect={setFormValues}
        virtual={false}
        onSearch={setSearchTerm}
        searchValue={searchTerm}
        loading={loading || debouncing}
        onDropdownVisibleChange={() => {}}
        notFoundContent={loading || debouncing ? <Spinner /> : "No Suggestions"}
      />
    </Form.Item>
  )
}

export default DealerNameExisting
